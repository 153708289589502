import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Skeleton,
} from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";

interface IDialogWrapperProps {
  title?: string;
  onClose?: () => void;
  open: boolean;
  children: JSX.Element | JSX.Element[];
  maxWidth?: DialogProps["maxWidth"];
  isLoading?: boolean;
}

const DialogWrapper = ({
  children,
  open,
  title,
  onClose,
  maxWidth = "md",
  isLoading = false,
}: IDialogWrapperProps) => {
  return (
    <Dialog maxWidth={maxWidth} fullWidth open={open}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={500} />
      ) : (
        <>
          <DialogTitle id="form-dialog-title">
            {title && title}
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </DialogTitle>
          <CustomDialogContent>{children}</CustomDialogContent>
        </>
      )}
    </Dialog>
  );
};

export default DialogWrapper;

const CloseButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;
const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  right: 16px;
  top: 16px;
  font-size: 24px;
  color: hsla(210, 17%, 69%, 1);
`;

const CustomDialogContent = styled(DialogContent)`
  padding-top: 10px !important;
`;
