import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { Control } from "react-hook-form";

interface ISelectOption {
  id: string;
  name: string;
}

interface IControlSelectFieldProps<
  U extends ISelectOption,
  T extends FieldValues = any
> {
  name: string;
  control: Control<T>;
  collection: U[];
  disabled: boolean;
  label: string;
  fullWidth?: boolean;
  required?: boolean;
  defaultValue?: string;
  native?: boolean;
  overrideOnChange?:
    | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
    | undefined;
}

const ControlSelectField = <U extends ISelectOption>({
  name,
  control,
  collection,
  disabled = false,
  label,
  fullWidth = true,
  required = false,
  defaultValue,
  native = false,
  overrideOnChange,
}: IControlSelectFieldProps<U>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          variant="outlined"
          required={required}
          fullWidth={fullWidth}
          error={!!error}
          disabled={disabled}
        >
          <InputLabel htmlFor={`outlined-select-control-${label}`}>
            {label}
          </InputLabel>
          <Select
            native={native}
            value={value}
            onChange={overrideOnChange ? overrideOnChange : onChange}
            label={label}
            defaultValue={defaultValue}
            inputProps={{
              name: label,
              id: `outlined-select-control-${label}`,
            }}
          >
            {native
              ? collection.map((item, i: number) => (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                ))
              : collection.map((item, index: number) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
export default ControlSelectField;
