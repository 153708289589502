import { useCallback, useState } from "react";
import * as Api from "../../Api";
import { getData } from "../../pages/Index";
import { ICompanysProps } from "../Companys/useEditCompanyModal";

export const useCrawlNewData = (refreshUseCompanysList: () => void) => {
  const [crawledData, setCrawledData] = useState<getData[] | null>(null);

  const clearCrawledData = () => {
    setCrawledData(null);
  };

  const handleCrawlCompany = useCallback(async (newOperation: string) => {
    setCrawledData((await Api.Crawl.crawlCompanyData(newOperation)).data);
  }, []);

  const handleCrawlNewInfo = useCallback(async (newInfo: ICompanysProps[]) => {
    console.log((await Api.Crawl.crawlNewInfo(newInfo)).data);
    refreshUseCompanysList();
  }, []);

  return {
    crawledData,
    clearCrawledData,
    handleCrawlCompany,
    handleCrawlNewInfo,
  };
};
