import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions } from "@mui/material";
import ControlTextField from "../Fields/ControlTextField";
import { ICompanysProps } from "../../Hooks/Companys/useEditCompanyModal";
import styled from "styled-components";
import ControlSelectField from "../Fields/ControlSelectField";
import { statusOption } from "../../utils/statusOptions";
import { SelectOperationOptions } from "../../utils/operations";

const editCompanySchema: yup.SchemaOf<ICompanysProps> = yup.object().shape({
  companyName: yup.string().required("error.required"),
  operationNr: yup.string().required("error.required"),
  industry: yup.string().required("error.required"),
  operation: yup.mixed().required("error.required"),
  area: yup.string(),
  city: yup.string(),
  id: yup.number().required("error.required"),
  lastUpdated: yup.string().required("error.required"),
  web: yup.string(),
  salesman: yup.string(),
  status: yup.mixed().required("error.required"),
});

interface ISalesman {
  id: string;
  name: string;
}

interface IEditCompanyFormProps {
  currentCompany: ICompanysProps;
  salesmans: ISalesman[];
  disabled: boolean;
  updateComopany: (data: ICompanysProps) => void;
}

const EditCompanyForm = ({
  currentCompany,
  salesmans,
  disabled,
  updateComopany,
}: IEditCompanyFormProps) => {
  const { control, handleSubmit, setValue, reset } = useForm<ICompanysProps>({
    defaultValues: {
      area: "",
      city: "",
      companyName: "",
      id: 0,
      lastUpdated: "",
      industry: "",
      operation: "",
      operationNr: "",
      web: "",
      salesman: "",
      status: "",
    },
    resolver: yupResolver(editCompanySchema),
  });

  useEffect(() => {
    setValue("area", currentCompany.area);
    setValue("city", currentCompany.city || "No city yet");
    setValue("companyName", currentCompany.companyName);
    setValue("id", currentCompany.id);
    setValue("lastUpdated", currentCompany.lastUpdated);
    setValue("industry", currentCompany.industry);
    setValue("operation", currentCompany.operation.toLowerCase());
    setValue("operationNr", currentCompany.operationNr);
    setValue("web", currentCompany.web || "No web");
    setValue("salesman", currentCompany.salesman || "No salesman");
    setValue("status", currentCompany.status);
    return () => reset();
  }, [currentCompany, setValue, reset]);

  return (
    <form onSubmit={handleSubmit((data) => updateComopany(data))}>
      <InputWrapper>
        <ControlTextField
          name="companyName"
          control={control}
          label="Company Name"
          disabled={disabled}
        />
        <ControlTextField
          name="operationNr"
          control={control}
          label="Operation Nr"
          disabled={disabled}
        />
        <ControlTextField
          name="industry"
          control={control}
          label="Industry"
          disabled={disabled}
        />
        <ControlSelectField
          name="operation"
          control={control}
          collection={SelectOperationOptions.map((option) => ({
            id: option.value.toLowerCase(),
            name: option.text.toLowerCase(),
          }))}
          disabled={disabled}
          label="Operation"
        />
        <ControlTextField
          name="area"
          control={control}
          label="Area"
          disabled={disabled}
        />
        <ControlTextField
          name="city"
          control={control}
          label="City"
          disabled={disabled}
        />
        <ControlTextField
          name="web"
          control={control}
          label="Web"
          disabled={disabled}
        />
        <ControlSelectField
          name="salesman"
          control={control}
          collection={salesmans}
          disabled={disabled}
          label="Salesman"
        />

        <ControlSelectField
          name="status"
          control={control}
          collection={statusOption.map((option) => ({
            id: option.value,
            name: option.text,
          }))}
          disabled={disabled}
          label="Status"
        />
      </InputWrapper>
      <DialogActions>
        <Button type="submit" variant="outlined" disabled={disabled}>
          Save
        </Button>
      </DialogActions>
    </form>
  );
};
export default EditCompanyForm;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
