export const Operations = [
  {
    name: "Ambassader",
    operations: [
      {
        url: "/bransch/ambassader-internationella-org/29/_",
        label: "Se allt inom Ambassader & Internationella Org.",
      },
      {
        url: "/bransch/ambassader-internationella-org/29/583",
        label: "Ambassader & Internationella Org.",
      },
    ],
  },
  {
    name: "Avlopp",
    operations: [
      {
        url: "/bransch/avlopp-avfall-el-vatten/5/_",
        label: "Se allt inom Avlopp, Avfall, El & Vatten",
      },
      {
        url: "/bransch/avfallshantering-atervinning/5/195",
        label: "Avfallshantering & Återvinning",
      },
      {
        url: "/bransch/avloppsrening/5/192",
        label: "Avloppsrening",
      },
      {
        url: "/bransch/eldistribution/5/185",
        label: "Eldistribution",
      },
      {
        url: "/bransch/elgenerering/5/183",
        label: "Elgenerering",
      },
      { url: "/bransch/elhandel/5/186", label: "Elhandel" },
      { url: "/bransch/eloverforing/5/184", label: "Elöverföring" },
      {
        url: "/bransch/farligt-avfall/5/194",
        label: "Farligt Avfall",
      },
      {
        url: "/bransch/gasdistribution/5/188",
        label: "Gasdistribution",
      },
      {
        url: "/bransch/gasframstallning/5/187",
        label: "Gasframställning",
      },
      { url: "/bransch/gashandel/5/189", label: "Gashandel" },
      {
        url: "/bransch/icke-farligt-avfall/5/193",
        label: "Icke-Farligt Avfall",
      },
      {
        url: "/bransch/kallsorterat-material/5/196",
        label: "Källsorterat Material",
      },
      {
        url: "/bransch/vatten-avlopp/5/191",
        label: "Vatten & Avlopp",
      },
      {
        url: "/bransch/varme-kyla/5/190",
        label: "Värme & Kyla",
      },
    ],
  },
  {
    name: "Bank",
    operations: [
      {
        url: "/bransch/bank-finans-forsakring/14/_",
        label: "Se allt inom Bank, Finans & Försäkring",
      },
      {
        url: "/bransch/centralbanksverksamhet/14/401",
        label: "Centralbanksverksamhet",
      },
      {
        url: "/bransch/finans-administrativa-tjanster/14/418",
        label: "Finans, Administrativa Tjänster",
      },
      {
        url: "/bransch/finansformedling-ovrig/14/412",
        label: "Finansförmedling, Övrig",
      },
      {
        url: "/bransch/finansiell-leasing/14/407",
        label: "Finansiell Leasing",
      },
      {
        url: "/bransch/finansiella-stodtjanster-ovriga/14/420",
        label: "Finansiella Stödtjänster, Övriga",
      },
      {
        url: "/bransch/fondanknuten-livforsakring/14/413",
        label: "Fondanknuten Livförsäkring",
      },
      {
        url: "/bransch/fonder-liknande-finansiella-enheter-ovriga/14/406",
        label: "Fonder & Liknande Finansiella Enheter, Övriga",
      },
      {
        url: "/bransch/fondforvaltning-ovrig/14/425",
        label: "Fondförvaltning, Övrig",
      },
      {
        url: "/bransch/forsakring-pensionsfond-stodtjanster-ovriga/14/423",
        label: "Försäkring & Pensionsfond Stödtjänster, Övriga",
      },
      {
        url: "/bransch/forvaltning-av-investeringsfonder/14/424",
        label: "Förvaltning Av Investeringsfonder",
      },
      {
        url: "/bransch/forvaltning-handel-med-vardepapper/14/410",
        label: "Förvaltning & Handel Med Värdepapper",
      },
      {
        url: "/bransch/holdingverksamhet-i-finansiella-koncerner/14/403",
        label: "Holdingverksamhet I Finansiella Koncerner",
      },
      {
        url: "/bransch/holdingverksamhet-i-icke-finansiella-koncerner/14/404",
        label: "Holdingverksamhet I Icke-Finansiella Koncerner",
      },
      {
        url: "/bransch/investeringsfonder/14/405",
        label: "Investeringsfonder",
      },
      {
        url: "/bransch/investment-riskkapitalbolag/14/409",
        label: "Investment- & Riskkapitalbolag",
      },
      {
        url: "/bransch/kreditgivning-ovrig/14/408",
        label: "Kreditgivning, Övrig",
      },
      {
        url: "/bransch/livforsakring-ovrig/14/414",
        label: "Livförsäkring, Övrig",
      },
      {
        url: "/bransch/monetar-finansformedling-ovrig/14/402",
        label: "Monetär Finansförmedling, Övrig",
      },
      {
        url: "/bransch/pensionsfondsverksamhet/14/417",
        label: "Pensionsfondsverksamhet",
      },
      {
        url: "/bransch/risk-skadebedomning/14/421",
        label: "Risk- & Skadebedömning",
      },
      {
        url: "/bransch/skadeforsakring/14/415",
        label: "Skadeförsäkring",
      },
      {
        url: "/bransch/verksamhet-utford-av-forsakringsombud-forsakringsmaklare/14/422",
        label: " Verksamhet Utförd Av Försäkringsombud & Försäkringsmäklare",
      },
      {
        url: "/bransch/verksamhet-utford-av-vardepappers-varumaklare/14/419",
        label: "Verksamhet Utförd Av Värdepappers- & Varumäklare",
      },
      {
        url: "/bransch/aterforsakring/14/416",
        label: "Återförsäkring",
      },
    ],
  },
  {
    name: "Bemanning",
    operations: [
      {
        url: "/bransch/bemanning-arbetsformedling/23/_",
        label: "Se allt inom Bemanning & Arbetsförmedling",
      },
      {
        url: "/bransch/arbetsformedling-rekrytering/23/474",
        label: "Arbetsförmedling & Rekrytering",
      },
      {
        url: "/bransch/personalfunktioner-ovrigt/23/476",
        label: "Personalfunktioner, Övrigt",
      },
      {
        url: "/bransch/personaluthyrning/23/475",
        label: "Personaluthyrning",
      },
    ],
  },
  {
    name: "Bransch",
    operations: [
      {
        url: "/bransch/bransch-arbetsgivar-yrkesorg/27/_",
        label: "Se allt inom Bransch-, Arbetsgivar- & Yrkesorg.",
      },
    ],
  },
  {
    name: "Bygg",
    operations: [
      {
        url: "/bransch/bygg-design-inredningsverksamhet/6/_",
        label: "Se allt inom Bygg-, Design- & Inredningsverksamhet",
      },
    ],
  },
  {
    name: "Data",
    operations: [
      {
        url: "/bransch/data-it-telekommunikation/13/_",
        label: "Se allt inom Data, IT & Telekommunikation",
      },
      {
        url: "/bransch/datakonsultverksamhet/13/394",
        label: "Datakonsultverksamhet",
      },
      {
        url: "/bransch/dataprogrammering/13/393",
        label: "Dataprogrammering",
      },
      {
        url: "/bransch/datordrifttjanster/13/395",
        label: "Datordrifttjänster",
      },
      {
        url: "/bransch/it-datatjanster-ovriga/13/396",
        label: "It- & Datatjänster, Övriga",
      },
      {
        url: "/bransch/telekommunikation-satellit/13/391",
        label: "Telekommunikation, Satellit",
      },
      {
        url: "/bransch/telekommunikation-tradbunden/13/389",
        label: "Telekommunikation, Trådbunden",
      },
      {
        url: "/bransch/telekommunikation-tradlos/13/390",
        label: "Telekommunikation, Trådlös",
      },
      {
        url: "/bransch/telekommunikation-ovrig/13/392",
        label: "Telekommunikation, Övrig",
      },
      {
        url: "/bransch/utgivning-av-annan-programvara/13/383",
        label: "Utgivning Av Annan Programvara",
      },
      {
        url: "/bransch/utgivning-av-dataspel/13/382",
        label: "Utgivning Av Dataspel",
      },
    ],
  },
  {
    name: "Detaljhandel",
    operations: [
      {
        url: "/bransch/detaljhandel/9/_",
        label: "Se allt inom Detaljhandel",
      },
      {
        url: "/bransch/alkoholhaltiga-drycker-butikshandel/9/287",
        label: "Alkoholhaltiga Drycker, Butikshandel",
      },
      {
        url: "/bransch/antikviteter-beg-bocker-butikshandel/9/334",
        label: "Antikviteter & Beg. Böcker, Butikshandel",
      },
      {
        url: "/bransch/apotekshandel/9/322",
        label: "Apotekshandel",
      },
      { url: "/bransch/auktioner/9/336", label: "Auktioner" },
      {
        url: "/bransch/band-skivor-butikshandel/9/310",
        label: "Band & Skivor, Butikshandel",
      },
      {
        url: "/bransch/barnklader-butikshandel/9/318",
        label: "Barnkläder, Butikshandel",
      },
      {
        url: "/bransch/begagnade-varor-ovriga-butikshandel/9/335",
        label: "Begagnade Varor Övriga, Butikshandel",
      },
      {
        url: "/bransch/belysningsartiklar-butikshandel/9/305",
        label: "Belysningsartiklar, Butikshandel",
      },
      {
        url: "/bransch/blommor-vaxter-butikshandel/9/325",
        label: "Blommor & Växter, Butikshandel",
      },
      {
        url: "/bransch/brod-konditori-butikshandel/9/285",
        label: "Bröd & Konditori, Butikshandel",
      },
      {
        url: "/bransch/butikshandel-ovrig/9/333",
        label: "Butikshandel, Övrig",
      },
      {
        url: "/bransch/batar-butikshandel/9/313",
        label: "Båtar, Butikshandel",
      },
      {
        url: "/bransch/bocker-butikshandel/9/307",
        label: "Böcker, Butikshandel",
      },
      {
        url: "/bransch/cyklar-butikshandel/9/312",
        label: "Cyklar, Butikshandel",
      },
      {
        url: "/bransch/damklader-butikshandel/9/317",
        label: "Damkläder, Butikshandel",
      },
      {
        url: "/bransch/datorer-program-data-tv-spel-butikshandel/9/292",
        label: "Datorer, Program, Data-& Tv-Spel, Butikshandel",
      },
      {
        url: "/bransch/detaljhandel-ovrig/9/281",
        label: "Detaljhandel, Övrig",
      },
      {
        url: "/bransch/drivmedel-detaljhandel/9/291",
        label: "Drivmedel, Detaljhandel",
      },
      {
        url: "/bransch/elektriska-hushallsmaskiner-butikshandel/9/301",
        label: "Elektriska Hushållsmaskiner, Butikshandel",
      },
      {
        url: "/bransch/fisk-skaldjur-butikshandel/9/284",
        label: "Fisk & Skaldjur, Butikshandel",
      },
      {
        url: "/bransch/fotoutrustning-butikshandel/9/330",
        label: "Fotoutrustning, Butikshandel",
      },
      {
        url: "/bransch/frukt-gronsaker-butikshandel/9/282",
        label: "Frukt & Grönsaker, Butikshandel",
      },
      {
        url: "/bransch/farg-lack-butikshandel/9/298",
        label: "Färg & Lack, Butikshandel",
      },
      {
        url: "/bransch/glas-porslin-butikshandel/9/304",
        label: "Glas & Porslin, Butikshandel",
      },
      {
        url: "/bransch/guldsmedsvaror-smycken-butikshandel/9/328",
        label: "Guldsmedsvaror & Smycken, Butikshandel",
      },
      {
        url: "/bransch/herrklader-butikshandel/9/316",
        label: "Herrkläder, Butikshandel",
      },
      {
        url: "/bransch/halsokost-butikshandel/9/289",
        label: "Hälsokost, Butikshandel",
      },
      {
        url: "/bransch/inredningstextilier-butikshandel/9/300",
        label: "Inredningstextilier, Butikshandel",
      },
      {
        url: "/bransch/jarn-vvs-varor-butikshandel/9/297",
        label: "Järn- & Vvs- Varor, Butikshandel",
      },
      {
        url: "/bransch/klockor-butikshandel/9/327",
        label: "Klockor, Butikshandel",
      },
      {
        url: "/bransch/klader-butikshandel/9/315",
        label: "Kläder, Butikshandel",
      },
      {
        url: "/bransch/konfektyr-butikshandel/9/286",
        label: "Konfektyr, Butikshandel",
      },
      {
        url: "/bransch/konst-galleri-butikshandel/9/331",
        label: "Konst & Galleri, Butikshandel",
      },
      {
        url: "/bransch/kontorsforbrukningsvaror-butikshandel/9/309",
        label: "Kontorsförbrukningsvaror, Butikshandel",
      },
      {
        url: "/bransch/kontorsmobler-butikshandel/9/303",
        label: "Kontorsmöbler, Butikshandel",
      },
      {
        url: "/bransch/kosmetika-hygienartiklar-butikshandel/9/324",
        label: "Kosmetika & Hygienartiklar, Butikshandel",
      },
      {
        url: "/bransch/kott-charkuterier-butikshandel/9/283",
        label: "Kött & Charkuterier, Butikshandel",
      },
      {
        url: "/bransch/livsmedel-ovriga-butikshandel/9/290",
        label: "Livsmedel Övriga, Butikshandel",
      },
      {
        url: "/bransch/livsmedelshandel/9/280",
        label: "Livsmedelshandel",
      },
      {
        url: "/bransch/ljud-bild-butikshandel/9/294",
        label: "Ljud & Bild, Butikshandel",
      },
      {
        url: "/bransch/mattor-vaggbekladnad-butikshandel/9/299",
        label: "Mattor & Väggbeklädnad, Butikshandel",
      },
      {
        url: "/bransch/musikinstrument-noter-butikshandel/9/306",
        label: "Musikinstrument & Noter, Butikshandel",
      },
      {
        url: "/bransch/mynt-frimarken-butikshandel/9/332",
        label: "Mynt & Frimärken, Butikshandel",
      },
      {
        url: "/bransch/mobler-for-hemmet-butikshandel/9/302",
        label: "Möbler För Hemmet, Butikshandel",
      },
      {
        url: "/bransch/optiker-butikshandel/9/329",
        label: "Optiker, Butikshandel",
      },
      {
        url: "/bransch/postorder-internethandel/9/338",
        label: "Postorder- & Internethandel",
      },
      {
        url: "/bransch/palsar-butikshandel/9/319",
        label: "Pälsar, Butikshandel",
      },
      {
        url: "/bransch/sjukvardsartiklar-butikshandel/9/323",
        label: "Sjukvårdsartiklar, Butikshandel",
      },
      {
        url: "/bransch/skor-butikshandel/9/320",
        label: "Skor, Butikshandel",
      },
      {
        url: "/bransch/spel-leksaker-butikshandel/9/314",
        label: "Spel & Leksaker, Butikshandel",
      },
      {
        url: "/bransch/sport-fritidsartiklar-butikshandel/9/311",
        label: "Sport- & Fritidsartiklar, Butikshandel",
      },
      {
        url: "/bransch/sallskapsdjur-butikshandel/9/326",
        label: "Sällskapsdjur, Butikshandel",
      },
      {
        url: "/bransch/telekommunikationsutrustning-butikshandel/9/293",
        label: "Telekommunikationsutrustning, Butikshandel",
      },
      {
        url: "/bransch/textilier-butikshandel/9/295",
        label: "Textilier, Butikshandel",
      },
      {
        url: "/bransch/tidningar-butikshandel/9/308",
        label: "Tidningar, Butikshandel",
      },
      {
        url: "/bransch/tobaksvaror-butikshandel/9/288",
        label: "Tobaksvaror, Butikshandel",
      },
      {
        url: "/bransch/torg-marknadshandel/9/337",
        label: "Torg- & Marknadshandel",
      },
      {
        url: "/bransch/varuhus-stormarknadshandel/9/279",
        label: "Varuhus- & Stormarknadshandel",
      },
      {
        url: "/bransch/virke-byggvaror-butikshandel/9/296",
        label: "Virke & Byggvaror, Butikshandel",
      },
      {
        url: "/bransch/vaskor-reseffekter-butikshandel/9/321",
        label: "Väskor & Reseffekter, Butikshandel",
      },
    ],
  },
  {
    name: "Fastighetsverksamhet",
    operations: [
      {
        url: "/bransch/fastighetsverksamhet/15/_",
        label: "Se allt inom Fastighetsverksamhet",
      },
      {
        url: "/bransch/fastighetsformedling/15/429",
        label: "Fastighetsförmedling",
      },
      {
        url: "/bransch/fastighetsforvaltning-pa-uppdrag/15/430",
        label: "Fastighetsförvaltning På Uppdrag",
      },
      {
        url: "/bransch/fastighetsrelaterade-stodtjanster/15/483",
        label: "Fastighetsrelaterade Stödtjänster",
      },
      {
        url: "/bransch/forvaltning-i-bostadsrattsforeningar/15/428",
        label: "Förvaltning I Bostadsrättsföreningar",
      },
      {
        url: "/bransch/handel-med-egna-fastigheter/15/426",
        label: "Handel Med Egna Fastigheter",
      },
      {
        url: "/bransch/rengoring-lokalvard/15/484",
        label: "Rengöring & Lokalvård",
      },
      {
        url: "/bransch/skotsel-underhall-av-gronytor/15/485",
        label: "Skötsel & Underhåll Av Grönytor",
      },
      {
        url: "/bransch/uthyrning-forvaltning-av-fastigheter/15/427",
        label: "Uthyrning & Förvaltning Av Fastigheter",
      },
    ],
  },
  {
    name: "Foretagstjanster",
    operations: [
      {
        url: "/bransch/foretagstjanster/11/_",
        label: "Se allt inom Företagstjänster",
      },
      {
        url: "/bransch/bud-kurirverksamhet/11/362",
        label: "Bud- & Kurirverksamhet",
      },
      {
        url: "/bransch/callcenterverksamhet/11/487",
        label: "Callcenterverksamhet",
      },
      {
        url: "/bransch/databehandling-hosting/11/397",
        label: "Databehandling & Hosting",
      },
      {
        url: "/bransch/fotolaboratorieverksamhet/11/458",
        label: "Fotolaboratorieverksamhet",
      },
      {
        url: "/bransch/foretagstjanster-ovriga/11/491",
        label: "Företagstjänster, Övriga",
      },
      {
        url: "/bransch/forpackningsverksamhet/11/490",
        label: "Förpackningsverksamhet",
      },
      {
        url: "/bransch/informationstjanster-ovriga/11/400",
        label: "Informationstjänster, Övriga",
      },
      {
        url: "/bransch/inkasso-kreditupplysningsverksamhet/11/489",
        label: "Inkasso- & Kreditupplysningsverksamhet",
      },
      {
        url: "/bransch/kongresser-massor/11/488",
        label: "Kongresser & Mässor",
      },
      {
        url: "/bransch/kontorstjanster/11/486",
        label: "Kontorstjänster",
      },
      {
        url: "/bransch/nyhetsservice/11/399",
        label: "Nyhetsservice",
      },
      {
        url: "/bransch/post-kurirverksamhet/11/361",
        label: "Post- & Kurirverksamhet",
      },
      {
        url: "/bransch/press-ovrig-fotografverksamhet/11/457",
        label: "Press- & Övrig Fotografverksamhet",
      },
      {
        url: "/bransch/reklamfotoverksamhet/11/456",
        label: "Reklamfotoverksamhet",
      },
      {
        url: "/bransch/spanings-detektivverksamhet/11/482",
        label: "Spanings- & Detektivverksamhet",
      },
      {
        url: "/bransch/sakerhetssystemtjanster/11/481",
        label: "Säkerhetssystemtjänster",
      },
      {
        url: "/bransch/sakerhetsverksamhet/11/480",
        label: "Säkerhetsverksamhet",
      },
      {
        url: "/bransch/tidningsdistribution/11/363",
        label: "Tidningsdistribution",
      },
      {
        url: "/bransch/verksamheter-som-utovas-av-huvudkontor/11/437",
        label: "Verksamheter Som Utövas Av Huvudkontor",
      },
      {
        url: "/bransch/webbportaler/11/398",
        label: "Webbportaler",
      },
    ],
  },
  {
    name: "Hotell",
    operations: [
      {
        url: "/bransch/hotell-restaurang/12/_",
        label: "Se allt inom Hotell & Restaurang",
      },
      {
        url: "/bransch/campingplatsverksamhet/12/368",
        label: "Campingplatsverksamhet",
      },
      { url: "/bransch/catering/12/371", label: "Catering" },
      {
        url: "/bransch/centralkok-for-sjukhus/12/373",
        label: "Centralkök För Sjukhus",
      },
      {
        url: "/bransch/centralkok-for-skolor-omsorg/12/374",
        label: "Centralkök För Skolor & Omsorg",
      },
      {
        url: "/bransch/hotell-restaurang/12/364",
        label: "Hotell & Restaurang",
      },
      {
        url: "/bransch/konferensanlaggningar/12/365",
        label: "Konferensanläggningar",
      },
      {
        url: "/bransch/logiverksamhet-ovrig/12/369",
        label: "Logiverksamhet, Övrig",
      },
      {
        url: "/bransch/personalmatsalar/12/372",
        label: "Personalmatsalar",
      },
      {
        url: "/bransch/restaurangverksamhet/12/370",
        label: "Restaurangverksamhet",
      },
      {
        url: "/bransch/stugbyverksamhet/12/367",
        label: "Stugbyverksamhet",
      },
      {
        url: "/bransch/vandrarhemsverksamhet/12/366",
        label: "Vandrarhemsverksamhet",
      },
    ],
  },
  {
    name: "Har",
    operations: [
      {
        url: "/bransch/har-skonhetsvard/28/_",
        label: "Se allt inom Hår & Skönhetsvård",
      },
      { url: "/bransch/frisorer/28/576", label: "Frisörer" },
      {
        url: "/bransch/skonhetsvard/28/577",
        label: "Skönhetsvård",
      },
    ],
  },
  {
    name: "Halsa",
    operations: [
      {
        url: "/bransch/halsa-sjukvard/21/_",
        label: "Se allt inom Hälsa & Sjukvård",
      },
      {
        url: "/bransch/halso-sjukvard-ovriga/21/537",
        label: "Hälso- & Sjukvård, Övriga",
      },
      {
        url: "/bransch/primarvardsmottagning/21/532",
        label: "Primärvårdsmottagning",
      },
      {
        url: "/bransch/sluten-sjukvard/21/531",
        label: "Sluten Sjukvård",
      },
      {
        url: "/bransch/sociala-insatser/21/539",
        label: "Sociala Insatser",
      },
      {
        url: "/bransch/specialistlakare-ej-pa-sjukhus/21/535",
        label: "Specialistläkare Ej På Sjukhus",
      },
      {
        url: "/bransch/specialistlakare-pa-sjukhus/21/534",
        label: "Specialistläkare På Sjukhus",
      },
      { url: "/bransch/tandlakare/21/536", label: "Tandläkare" },
      {
        url: "/bransch/veterinarverksamhet/21/461",
        label: "Veterinärverksamhet",
      },
      {
        url: "/bransch/vard-omsorg-med-boende/21/538",
        label: "Vård & Omsorg Med Boende",
      },
      {
        url: "/bransch/oppen-halso-sjukvard/21/533",
        label: "Öppen Hälso- & Sjukvård",
      },
    ],
  },
  {
    name: "Jordbruk",
    operations: [
      {
        url: "/bransch/jordbruk-skogsbruk-jakt-fiske/0/_",
        label: "Se allt inom Jordbruk, Skogsbruk, Jakt & Fiske",
      },
      {
        url: "/bransch/blandat-jordbruk/0/26",
        label: "Blandat Jordbruk",
      },
      {
        url: "/bransch/djuruppfodning-ovrig/0/25",
        label: "Djuruppfödning, Övrig",
      },
      { url: "/bransch/drivning/0/34", label: "Drivning" },
      {
        url: "/bransch/fibervaxtodling/0/9",
        label: "Fiberväxtodling",
      },
      { url: "/bransch/fiskodling/0/39", label: "Fiskodling" },
      {
        url: "/bransch/fjaderfa-uppfodning/0/22",
        label: "Fjäderfä, Uppfödning",
      },
      {
        url: "/bransch/frukt-bar-notter-odling/0/12",
        label: "Frukt, Bär & Nötter, Odling",
      },
      { url: "/bransch/fruktodling/0/11", label: "Fruktodling" },
      {
        url: "/bransch/far-getuppfodning/0/18",
        label: "Får- & Getuppfödning",
      },
      {
        url: "/bransch/gronsaker-frilandsodling/0/4",
        label: "Grönsaker, Frilandsodling",
      },
      {
        url: "/bransch/gronsaker-vaxthusodling/0/5",
        label: "Grönsaker, Växthusodling",
      },
      {
        url: "/bransch/hastuppfodning/0/16",
        label: "Hästuppfödning",
      },
      { url: "/bransch/jakt/0/30", label: "Jakt" },
      {
        url: "/bransch/kamel-kameldjursuppfodning/0/17",
        label: "Kamel- & Kameldjursuppfödning",
      },
      {
        url: "/bransch/mjolkproduktion-notkreatursuppfodning/0/14",
        label: "Mjölkproduktion & Nötkreatursuppfödning",
      },
      {
        url: "/bransch/notkreatur-bufflar-ovriga/0/15",
        label: "Nötkreatur & Bufflar, Övriga",
      },
      {
        url: "/bransch/plantskolevaxter-odling/0/13",
        label: "Plantskoleväxter, Odling",
      },
      { url: "/bransch/potatisodling/0/2", label: "Potatisodling" },
      { url: "/bransch/renskotsel/0/23", label: "Renskötsel" },
      {
        url: "/bransch/saltvattensfiske/0/37",
        label: "Saltvattensfiske",
      },
      {
        url: "/bransch/service-till-husdjursskotsel/0/28",
        label: "Service Till Husdjursskötsel",
      },
      {
        url: "/bransch/service-till-skogsbruk/0/36",
        label: "Service Till Skogsbruk",
      },
      {
        url: "/bransch/service-till-vaxtodling/0/27",
        label: "Service Till Växtodling",
      },
      { url: "/bransch/skogsbruk/0/33", label: "Skogsbruk" },
      {
        url: "/bransch/skogsforvaltning/0/31",
        label: "Skogsförvaltning",
      },
      { url: "/bransch/skogsskotsel/0/32", label: "Skogsskötsel" },
      {
        url: "/bransch/slaktsvinuppfodning/0/20",
        label: "Slaktsvinuppfödning",
      },
      {
        url: "/bransch/smagrisuppfodning/0/19",
        label: "Smågrisuppfödning",
      },
      {
        url: "/bransch/socketbetsodling/0/3",
        label: "Socketbetsodling",
      },
      {
        url: "/bransch/spannmal-balj-oljevaxter-odling/0/0",
        label: "Spannmål, Balj- & Oljeväxter, Odling",
      },
      { url: "/bransch/svampodling/0/6", label: "Svampodling" },
      {
        url: "/bransch/sallskapsdjur-uppfodning/0/24",
        label: "Sällskapsdjur, Uppfödning",
      },
      {
        url: "/bransch/sotvattensfiske/0/38",
        label: "Sötvattensfiske",
      },
      { url: "/bransch/tobaksodling/0/8", label: "Tobaksodling" },
      { url: "/bransch/virkesmatning/0/35", label: "Virkesmätning" },
      {
        url: "/bransch/vaxter-bearbetning/0/29",
        label: "Växter, Bearbetning",
      },
      { url: "/bransch/vaxtodling/0/10", label: "Växtodling" },
      { url: "/bransch/aggproduktion/0/21", label: "Äggproduktion" },
    ],
  },
  {
    name: "Juridik",
    operations: [
      {
        url: "/bransch/juridik-ekonomi-konsulttjanster/16/_",
        label: "Se allt inom Juridik, Ekonomi & Konsulttjänster",
      },
      {
        url: "/bransch/advokatbyraer/16/431",
        label: "Advokatbyråer",
      },
      {
        url: "/bransch/juridisk-verksamhet-ovrig/16/432",
        label: "Juridisk Verksamhet, Övrig",
      },
      {
        url: "/bransch/konsultverksamhet-avseende-foretags-org/16/439",
        label: "Konsultverksamhet Avseende Företags Org.",
      },
      { url: "/bransch/patentbyraer/16/433", label: "Patentbyråer" },
      {
        url: "/bransch/redovisning-bokforing/16/434",
        label: "Redovisning & Bokföring",
      },
      { url: "/bransch/revision/16/435", label: "Revision" },
      {
        url: "/bransch/skatteradgivning/16/436",
        label: "Skatterådgivning",
      },
    ],
  },
  {
    name: "Kultur",
    operations: [
      {
        url: "/bransch/kultur-noje-fritid/26/_",
        label: "Se allt inom Kultur, Nöje & Fritid",
      },
      {
        url: "/bransch/arkivverksamhet/26/544",
        label: "Arkivverksamhet",
      },
      {
        url: "/bransch/artistisk-verksamhet/26/540",
        label: "Artistisk Verksamhet",
      },
      { url: "/bransch/bibliotek/26/543", label: "Bibliotek" },
      {
        url: "/bransch/fritids-nojesverksamhet-ovrig/26/559",
        label: "Fritids-& Nöjesverksamhet, Övrig",
      },
      { url: "/bransch/golfbanor/26/550", label: "Golfbanor" },
      {
        url: "/bransch/gymanlaggningar/26/555",
        label: "Gymanläggningar",
      },
      {
        url: "/bransch/idrottsplatser-sportanlaggningar/26/553",
        label: "Idrottsplatser & Sportanläggningar",
      },
      {
        url: "/bransch/kultur-noje-fritid/26/541",
        label: "Kultur, Nöje & Fritid",
      },
      {
        url: "/bransch/litterart-konstnarligt-skapande/26/542",
        label: "Litterärt & Konstnärligt Skapande",
      },
      { url: "/bransch/motorbanor/26/551", label: "Motorbanor" },
      {
        url: "/bransch/museiverksamhet/26/545",
        label: "Museiverksamhet",
      },
      {
        url: "/bransch/nojes-temaparksverksamhet/26/558",
        label: "Nöjes- & Temaparksverksamhet",
      },
      {
        url: "/bransch/skidsportanlaggningar/26/549",
        label: "Skidsportanläggningar",
      },
      {
        url: "/bransch/spel-vadhallningsverksamhet/26/548",
        label: "Spel- & Vadhållningsverksamhet",
      },
      {
        url: "/bransch/sportklubbars-idrottsfor-verksamhet/26/554",
        label: "Sportklubbars & Idrottsför. Verksamhet",
      },
      {
        url: "/bransch/sportverksamhet-ovrig/26/557",
        label: "Sportverksamhet, Övrig",
      },
      {
        url: "/bransch/trav-galoppbanor/26/552",
        label: "Trav- & Galoppbanor",
      },
      {
        url: "/bransch/tradgardar-djurparker-naturreservat-drift/26/547",
        label: "Trädgårdar, Djurparker & Naturreservat, Drift",
      },
      {
        url: "/bransch/tavling-med-hastar/26/556",
        label: "Tävling Med Hästar",
      },
      {
        url: "/bransch/vard-av-historiska-minnesmarken-byggnader/26/546",
        label: "Vård Av Historiska Minnesmärken & Byggnader",
      },
    ],
  },
  {
    name: "Livsmedelsframstallning",
    operations: [
      {
        url: "/bransch/livsmedelsframstallning/2/_",
        label: "Se allt inom Livsmedelsframställning",
      },
      {
        url: "/bransch/bageri-mjolprodukter/2/60",
        label: "Bageri- & Mjölprodukter",
      },
      {
        url: "/bransch/choklad-konfektyrtillverkning/2/62",
        label: "Choklad- & Konfektyrtillverkning",
      },
      {
        url: "/bransch/djurfoderframstallning/2/64",
        label: "Djurfoderframställning",
      },
      {
        url: "/bransch/dryckesframstallning/2/65",
        label: "Dryckesframställning",
      },
      {
        url: "/bransch/fisk-skaldjur-blotdjur-beredning/2/52",
        label: "Fisk, Skaldjur & Blötdjur, Beredning",
      },
      {
        url: "/bransch/glasstillverkning/2/57",
        label: "Glasstillverkning",
      },
      {
        url: "/bransch/juice-safttillverkning/2/54",
        label: "Juice & Safttillverkning",
      },
      {
        url: "/bransch/kvarnprodukter/2/58",
        label: "Kvarnprodukter",
      },
      { url: "/bransch/kottprodukter/2/51", label: "Köttprodukter" },
      {
        url: "/bransch/livsmedelsframstallning/2/55",
        label: "Livsmedelsframställning",
      },
      {
        url: "/bransch/mejerivarutillverkning/2/56",
        label: "Mejerivarutillverkning",
      },
      {
        url: "/bransch/potatisberedning/2/53",
        label: "Potatisberedning",
      },
      {
        url: "/bransch/sockertillverkning/2/61",
        label: "Sockertillverkning",
      },
      {
        url: "/bransch/starkelsetillverkning/2/59",
        label: "Stärkelsetillverkning",
      },
      {
        url: "/bransch/te-kaffetillverkning/2/63",
        label: "Te- & Kaffetillverkning",
      },
      {
        url: "/bransch/tobaksvarutillverkning/2/66",
        label: "Tobaksvarutillverkning",
      },
    ],
  },
  {
    name: "Media",
    operations: [
      {
        url: "/bransch/media/3/_",
        label: "Se allt inom Media",
      },
      {
        url: "/bransch/annonstidningsutgivning/3/379",
        label: "Annonstidningsutgivning",
      },
      {
        url: "/bransch/bokutgivning/3/376",
        label: "Bokutgivning",
      },
      {
        url: "/bransch/dagstidningsutgivning/3/378",
        label: "Dagstidningsutgivning",
      },
      {
        url: "/bransch/film-video-tv/3/384",
        label: "Film, Video & Tv",
      },
      { url: "/bransch/filmvisning/3/385", label: "Filmvisning" },
      {
        url: "/bransch/forlagsverksamhet-ovrig/3/381",
        label: "Förlagsverksamhet, Övrig",
      },
      {
        url: "/bransch/ljudinspelning-fonogramutgivning/3/386",
        label: "Ljudinspelning & Fonogramutgivning",
      },
      {
        url: "/bransch/publicering-av-kataloger-sandlistor/3/377",
        label: "Publicering Av Kataloger & Sändlistor",
      },
      {
        url: "/bransch/radiosandning/3/387",
        label: "Radiosändning",
      },
      {
        url: "/bransch/reproduktion-av-inspelningar/3/86",
        label: "Reproduktion Av Inspelningar",
      },
      {
        url: "/bransch/tv-program-planering/3/388",
        label: "Tv-Program Planering",
      },
      {
        url: "/bransch/utgivning-av-tidskrifter/3/380",
        label: "Utgivning Av Tidskrifter",
      },
    ],
  },
  {
    name: "Motorfordonshandel",
    operations: [
      {
        url: "/bransch/motorfordonshandel/7/_",
        label: "Se allt inom Motorfordonshandel",
      },
      {
        url: "/bransch/husvagnar-husbilar-slap-handel/7/213",
        label: "Husvagnar, Husbilar & Släp, Handel",
      },
      {
        url: "/bransch/lastbilar-bussar-specialfordon-handel/7/212",
        label: "Lastbilar, Bussar & Specialfordon, Handel",
      },
      {
        url: "/bransch/personbilar-latta-motorfordon-handel/7/211",
        label: "Personbilar & Lätta Motorfordon, Handel",
      },
    ],
  },
  {
    name: "Offentlig",
    operations: [
      {
        url: "/bransch/offentlig-forvaltning-samhalle/25/_",
        label: "Se allt inom Offentlig förvaltning & Samhälle",
      },
      {
        url: "/bransch/arbetsmarknadsprogram/25/504",
        label: "Arbetsmarknadsprogram",
      },
      {
        url: "/bransch/brand-raddningsverksamhet/25/514",
        label: "Brand- & Räddningsverksamhet",
      },
      {
        url: "/bransch/civilt-forsvar-frivilligforsvar/25/509",
        label: "Civilt Försvar & Frivilligförsvar",
      },
      {
        url: "/bransch/domstolsverksamhet/25/511",
        label: "Domstolsverksamhet",
      },
      {
        url: "/bransch/grundskole-gymnasieskoleutbildning/25/497",
        label: "Grundskole- & Gymnasieskoleutbildning",
      },
      {
        url: "/bransch/halso-sjukvard-administration/25/499",
        label: "Hälso- & Sjukvård, Administration",
      },
      {
        url: "/bransch/infrastrukturprogram/25/502",
        label: "Infrastrukturprogram",
      },
      {
        url: "/bransch/inspektion-kontroll-tillstandsgivning/25/493",
        label: "Inspektion, Kontroll & Tillståndsgivning",
      },
      {
        url: "/bransch/jord-skogsbruk-administration-av-program/25/503",
        label: "Jord- & Skogsbruk, Administration Av Program",
      },
      { url: "/bransch/kriminalvard/25/512", label: "Kriminalvård" },
      {
        url: "/bransch/kultur-miljo-boende-administration/25/501",
        label: "Kultur, Miljö, Boende, Administration",
      },
      {
        url: "/bransch/militart-forsvar/25/507",
        label: "Militärt Försvar",
      },
      {
        url: "/bransch/naringslivsprogram-ovriga/25/505",
        label: "Näringslivsprogram, Övriga",
      },
      {
        url: "/bransch/obligatorisk-socialforsakring/25/515",
        label: "Obligatorisk Socialförsäkring",
      },
      {
        url: "/bransch/omsorg-socialtjanst/25/500",
        label: "Omsorg & Socialtjänst",
      },
      {
        url: "/bransch/personalforvaltning-andra-stodtjanster/25/496",
        label: "Personalförvaltning & Andra Stödtjänster",
      },
      {
        url: "/bransch/polisverksamhet/25/513",
        label: "Polisverksamhet",
      },
      {
        url: "/bransch/samhallelig-informationsforsorjning/25/495",
        label: "Samhällelig Informationsförsörjning",
      },
      {
        url: "/bransch/skatteforvaltning-indrivning/25/494",
        label: "Skatteförvaltning & Indrivning",
      },
      {
        url: "/bransch/stats-kommunledning-lagstiftning-planering/25/492",
        label: "Stats- & Kommunledning, Lagstiftning & Planering",
      },
      {
        url: "/bransch/totalforsvaret/25/508",
        label: "Totalförsvaret",
      },
      {
        url: "/bransch/universitets-hogskoleutbildning-samt-forskning/25/498",
        label: "Universitets- & Högskoleutbildning Samt Forskning",
      },
      {
        url: "/bransch/utrikesforvaltning/25/506",
        label: "Utrikesförvaltning",
      },
      {
        url: "/bransch/aklagarverksamhet/25/510",
        label: "Åklagarverksamhet",
      },
    ],
  },
  {
    name: "Partihandel",
    operations: [
      {
        url: "/bransch/partihandel/8/_",
        label: "Se allt inom Partihandel",
      },
      {
        url: "/bransch/avfall-skrot-partihandel/8/277",
        label: "Avfall & Skrot, Partihandel",
      },
      {
        url: "/bransch/band-skivor-partihandel/8/244",
        label: "Band & Skivor, Partihandel",
      },
      {
        url: "/bransch/blandat-sortiment/8/226",
        label: "Blandat Sortiment",
      },
      {
        url: "/bransch/blommor-vaxter-partihandel/8/228",
        label: "Blommor & Växter, Partihandel",
      },
      {
        url: "/bransch/bransle-mineraler-industrikem-partihandel/8/218",
        label: "Bränsle, Mineraler & Industrikem. Partihandel",
      },
      {
        url: "/bransch/datorer-program-kringutr-partihandel/8/255",
        label: "Datorer, Program & Kringutr, Partihandel",
      },
      {
        url: "/bransch/datoriserad-materialhanteringsutr-partihandel/8/265",
        label: "Datoriserad Materialhanteringsutr, Partihandel",
      },
      {
        url: "/bransch/drycker-partihandel/8/234",
        label: "Drycker, Partihandel",
      },
      {
        url: "/bransch/elartiklar-partihandel/8/245",
        label: "Elartiklar, Partihandel",
      },
      {
        url: "/bransch/elektronikkomponenter-partihandel/8/256",
        label: "Elektronikkomponenter, Partihandel",
      },
      {
        url: "/bransch/emballage-partihandel/8/273",
        label: "Emballage, Partihandel",
      },
      {
        url: "/bransch/fisk-skalddjur-andra-livsmedel-partihandel/8/238",
        label: "Fisk, Skalddjur & Andra Livsmedel, Partihandel",
      },
      {
        url: "/bransch/fotografiska-optiska-produkter-partihandel/8/246",
        label: "Fotografiska & Optiska Produkter, Partihandel",
      },
      {
        url: "/bransch/frukt-gronsaker-partihandel/8/231",
        label: "Frukt & Grönsaker, Partihandel",
      },
      {
        url: "/bransch/glas-porslin-rengoringsmedel-partihandel/8/247",
        label: "Glas, Porslin & Rengöringsmedel, Partihandel",
      },
      {
        url: "/bransch/gruv-bygg-anlaggningsmaskiner-partihandel/8/260",
        label: "Gruv-, Bygg- & Anläggningsmaskiner, Partihandel",
      },
      {
        url: "/bransch/hudar-skinn-lader-partihandel/8/230",
        label: "Hudar, Skinn & Läder, Partihandel",
      },
      {
        url: "/bransch/hushallsapparater-elartiklar-partihandel/8/242",
        label: "Hushållsapparater & Elartiklar, Partihandel",
      },
      {
        url: "/bransch/hushallsvaror-ovriga-partihandel/8/254",
        label: "Hushållsvaror Övriga, Partihandel",
      },
      {
        url: "/bransch/icke-spec-handel-med-livsmedel-partihandel/8/239",
        label: "Icke Spec. Handel Med Livsmedel, Partihandel",
      },
      {
        url: "/bransch/industrifornodenheter-partihandel/8/272",
        label: "Industriförnödenheter, Partihandel",
      },
      {
        url: "/bransch/insatsvaror-ovriga-partihandel/8/274",
        label: "Insatsvaror Övriga, Partihandel",
      },
      {
        url: "/bransch/jordbruksmaskiner-partihandel/8/258",
        label: "Jordbruksmaskiner, Partihandel",
      },
      {
        url: "/bransch/jordbruks-textilravaror-provisionshandel/8/217",
        label: "Jordbruks- & Textilråvaror, Provisionshandel",
      },
      {
        url: "/bransch/jarnhandelsvaror-partihandel/8/269",
        label: "Järnhandelsvaror, Partihandel",
      },
      {
        url: "/bransch/kaffe-te-kakao-kryddor-partihandel/8/237",
        label: "Kaffe, Te, Kakao & Kryddor, Partihandel",
      },
      {
        url: "/bransch/kemiska-produkter-partihandel/8/271",
        label: "Kemiska Produkter, Partihandel",
      },
      {
        url: "/bransch/klader-skor-partihandel/8/241",
        label: "Kläder & Skor, Partihandel",
      },
      {
        url: "/bransch/kontorsforbrukningsvaror-partihandel/8/253",
        label: "Kontorsförbrukningsvaror, Partihandel",
      },
      {
        url: "/bransch/kontorsmaskiner-kontorsutrustning-partihandel/8/263",
        label: "Kontorsmaskiner & Kontorsutrustning, Partihandel",
      },
      {
        url: "/bransch/kontorsmobler-partihandel/8/262",
        label: "Kontorsmöbler, Partihandel",
      },
      {
        url: "/bransch/kontorsutrustning-datorer-partihandel/8/221",
        label: "Kontorsutrustning & Datorer, Partihandel",
      },
      {
        url: "/bransch/kott-kottvaror-partihandel/8/232",
        label: "Kött & Köttvaror, Partihandel",
      },
      {
        url: "/bransch/levande-djur-partihandel/8/229",
        label: "Levande Djur, Partihandel",
      },
      {
        url: "/bransch/livsmedel-dryck-tobak-partihandel/8/224",
        label: "Livsmedel, Dryck & Tobak, Partihandel",
      },
      {
        url: "/bransch/ljud-bild-videoutrustning-partihandel/8/243",
        label: "Ljud, Bild & Videoutrustning, Partihandel",
      },
      {
        url: "/bransch/maskiner-utrustning-ovriga-partihandel/8/266",
        label: "Maskiner & Utrustning Övriga, Partihandel",
      },
      {
        url: "/bransch/maskiner-industriell-utrustning-partihandel/8/220",
        label: "Maskiner, Industriell Utrustning, Partihandel",
      },
      {
        url: "/bransch/medicinsk-utrustning-apoteksvaror-partihandel/8/249",
        label: "Medicinsk Utrustning & Apoteksvaror, Partihandel",
      },
      {
        url: "/bransch/mejeriprodukter-agg-matolja-matfett-partihandel/8/233",
        label: "Mejeriprodukter, Ägg, Matolja & Matfett, Partihandel",
      },
      {
        url: "/bransch/metallavfall-metallskrot-partihandel/8/276",
        label: "Metallavfall & Metallskrot, Partihandel",
      },
      {
        url: "/bransch/metaller-metallmalmer-partihandel/8/267",
        label: "Metaller & Metallmalmer, Partihandel",
      },
      {
        url: "/bransch/motorcyklar-handel-service-tillbehor/8/216",
        label: "Motorcyklar, Handel, Service & Tillbehör",
      },
      {
        url: "/bransch/motorfordon-reservdelar-tillbehor/8/215",
        label: "Motorfordon, Reservdelar & Tillbehör",
      },
      {
        url: "/bransch/mat-precisionsinstrument-partihandel/8/264",
        label: "Mät- & Precisionsinstrument, Partihandel",
      },
      {
        url: "/bransch/mobler-hushalls-jarnhandelsvaror-partihandel/8/222",
        label: "Möbler, Hushålls- & Järnhandelsvaror, Partihandel",
      },
      {
        url: "/bransch/mobler-mattor-belysning-partihandel/8/250",
        label: "Möbler, Mattor & Belysning, Partihandel",
      },
      {
        url: "/bransch/parfym-kosmetika-partihandel/8/248",
        label: "Parfym & Kosmetika, Partihandel",
      },
      { url: "/bransch/partihandel/8/227", label: "Partihandel" },
      {
        url: "/bransch/partihandel-ovrig/8/278",
        label: "Partihandel, Övrig",
      },
      {
        url: "/bransch/sanitetsgods-partihandel/8/268",
        label: "Sanitetsgods, Partihandel",
      },
      {
        url: "/bransch/socker-choklad-sockerkonfekt-partihandel/8/236",
        label: "Socker, Choklad & Sockerkonfekt, Partihandel",
      },
      {
        url: "/bransch/specialsortiment/8/225",
        label: "Specialsortiment",
      },
      {
        url: "/bransch/sport-fritidsartiklar-partihandel/8/252",
        label: "Sport- & Fritidsartiklar, Partihandel",
      },
      {
        url: "/bransch/teleprodukter-partihandel/8/257",
        label: "Teleprodukter, Partihandel",
      },
      {
        url: "/bransch/textilier-klader-skodon-partihandel/8/223",
        label: "Textilier, Kläder & Skodon, Partihandel",
      },
      {
        url: "/bransch/textilier-partihandel/8/240",
        label: "Textilier, Partihandel",
      },
      {
        url: "/bransch/textil-sy-stickmaskiner-partihandel/8/261",
        label: "Textil-, Sy- & Stickmaskiner, Partihandel",
      },
      {
        url: "/bransch/tobak-partihandel/8/235",
        label: "Tobak, Partihandel",
      },
      {
        url: "/bransch/ur-guldssmedsvaror-partihandel/8/251",
        label: "Ur & Guldssmedsvaror, Partihandel",
      },
      {
        url: "/bransch/uttjanta-fordon-partihandel/8/275",
        label: "Uttjänta Fordon, Partihandel",
      },
      {
        url: "/bransch/verktygsmaskiner-partihandel/8/259",
        label: "Verktygsmaskiner, Partihandel",
      },
      {
        url: "/bransch/virke-byggmaterial-partihandel/8/219",
        label: "Virke & Byggmaterial, Partihandel",
      },
      {
        url: "/bransch/vvs-varor-partihandel/8/270",
        label: "Vvs-Varor, Partihandel",
      },
    ],
  },
  {
    name: "Reklam",
    operations: [
      {
        url: "/bransch/reklam-pr-marknadsundersokning/17/_",
        label: "Se allt inom Reklam, PR & Marknadsundersökning",
      },
      {
        url: "/bransch/direktreklamverksamhet/17/450",
        label: "Direktreklamverksamhet",
      },
      {
        url: "/bransch/marknads-opinionsundersokning/17/451",
        label: "Marknads- & Opinionsundersökning",
      },
      {
        url: "/bransch/reklam-pr-mediebyra-annonsforsalj/17/438",
        label: "Reklam, Pr, Mediebyrå & Annonsförsälj.",
      },
    ],
  },
  {
    name: "Reparation",
    operations: [
      {
        url: "/bransch/datorer-kringutrustning-reparation/4/567",
        label: "Datorer & Kringutrustning, Reparation",
      },
      {
        url: "/bransch/elapparatur-reparation/4/177",
        label: "Elapparatur, Reparation",
      },
      {
        url: "/bransch/elektronisk-optisk-utrustning-reparation/4/176",
        label: "Elektronisk & Optisk Utrustning, Reparation",
      },
      {
        url: "/bransch/fartyg-batar-reparation/4/178",
        label: "Fartyg & Båtar, Reparation",
      },
      {
        url: "/bransch/hemelektronik-reparation/4/569",
        label: "Hemelektronik, Reparation",
      },
      {
        url: "/bransch/hushallsapparater-hem-tradgard-reparation/4/570",
        label: "Hushållsapparater, Hem & Trädgård, Reparation",
      },
      {
        url: "/bransch/hushalls-personartiklar-reparation-ovriga/4/574",
        label: "Hushålls- & Personartiklar, Reparation, Övriga",
      },
      {
        url: "/bransch/industri-maskiner-utrustning-installation/4/182",
        label: "Industri- Maskiner & Utrustning, Installation",
      },
      {
        url: "/bransch/kommunikationsutrustning-reparation/4/568",
        label: "Kommunikationsutrustning, Reparation",
      },
      {
        url: "/bransch/luftfartyg-rymdfarkoster-reparation/4/179",
        label: "Luftfartyg & Rymdfarkoster, Reparation",
      },
      {
        url: "/bransch/maskiner-reparation/4/175",
        label: "Maskiner, Reparation",
      },
      {
        url: "/bransch/metallvaror-reparation/4/174",
        label: "Metallvaror, Reparation",
      },
      {
        url: "/bransch/motorfordon-reparation-underhall/4/214",
        label: "Motorfordon, Reparation & Underhåll",
      },
      {
        url: "/bransch/mobler-heminredning-reparation/4/572",
        label: "Möbler & Heminredning, Reparation",
      },
      { url: "/bransch/skomakare/4/571", label: "Skomakare" },
      {
        url: "/bransch/transportmedel-ovriga-reparation/4/180",
        label: "Transportmedel Övriga, Reparation",
      },
      {
        url: "/bransch/ur-guldsmedsvaror-reparation/4/573",
        label: "Ur & Guldsmedsvaror, Reparation",
      },
      {
        url: "/bransch/utrustning-reparation-ovrig/4/181",
        label: "Utrustning Reparation, Övrig",
      },
    ],
  },
  {
    name: "Resebyra",
    operations: [
      {
        url: "/bransch/resebyra-turism/24/_",
        label: "Se allt inom Resebyrå & Turism",
      },
      {
        url: "/bransch/researrangemang/24/478",
        label: "Researrangemang",
      },
      { url: "/bransch/resebyraer/24/477", label: "Resebyråer" },
      {
        url: "/bransch/turist-bokningsservice/24/479",
        label: "Turist- & Bokningsservice",
      },
    ],
  },
  {
    name: "Teknisk",
    operations: [
      {
        url: "/bransch/teknisk-konsultverksamhet/18/_",
        label: "Se allt inom Teknisk Konsultverksamhet",
      },
      {
        url: "/bransch/juridik-ekonomi-vetenskap-teknik-ovrig/18/460",
        label: "Juridik, Ekonomi, Vetenskap & Teknik, Övrig",
      },
      {
        url: "/bransch/teknisk-konsult-inom-elteknik/18/443",
        label: "Teknisk Konsult Inom Elteknik",
      },
      {
        url: "/bransch/teknisk-konsult-inom-energi-miljo-vvs-teknik/18/444",
        label: "Teknisk Konsult Inom Energi-, Miljö- & Vvs-Teknik",
      },
      {
        url: "/bransch/teknisk-konsult-inom-industriteknik/18/442",
        label: "Teknisk Konsult Inom Industriteknik",
      },
      {
        url: "/bransch/teknisk-konsultverksamhet-ovrig/18/445",
        label: "Teknisk Konsultverksamhet, Övrig",
      },
      {
        url: "/bransch/teknisk-provning-analys/18/446",
        label: "Teknisk Provning & Analys",
      },
    ],
  },
  {
    name: "Tillverkning",
    operations: [
      {
        url: "/bransch/tillverkning-industri/1/_",
        label: "Se allt inom Tillverkning & Industri",
      },
      {
        url: "/bransch/arbets-skyddsklader-tillverkning/1/72",
        label: "Arbets- & Skyddskläder, Tillverkning",
      },
      {
        url: "/bransch/basplast-tillverkning/1/94",
        label: "Basplast, Tillverkning",
      },
      {
        url: "/bransch/batteri-och-ackumulatortillverkning/1/139",
        label: "Batteri- Och Ackumulatortillverkning",
      },
      {
        url: "/bransch/bekampningsmedel-lantbrukskem-tillverkning/1/96",
        label: "Bekämpningsmedel & Lantbrukskem, Tillverkning",
      },
      {
        url: "/bransch/belysningsarmaturtillverkning/1/142",
        label: "Belysningsarmaturtillverkning",
      },
      {
        url: "/bransch/betongvarutillverkning/1/120",
        label: "Betongvarutillverkning",
      },
      {
        url: "/bransch/betong-cement-gipsvaror-ovriga/1/125",
        label: "Betong-, Cement- & Gipsvaror, Övriga",
      },
      {
        url: "/bransch/bijouteritillverkning/1/166",
        label: "Bijouteritillverkning",
      },
      {
        url: "/bransch/borstbinderitillverkning/1/172",
        label: "Borstbinderitillverkning",
      },
      {
        url: "/bransch/byggmaterialtillverkning/1/116",
        label: "Byggmaterialtillverkning",
      },
      {
        url: "/bransch/byggnadsmetallvaror-tillverkning/1/129",
        label: "Byggnadsmetallvaror, Tillverkning",
      },
      {
        url: "/bransch/byggplastvarutillverkning/1/109",
        label: "Byggplastvarutillverkning",
      },
      {
        url: "/bransch/bat-fartyg-tillverkning/1/152",
        label: "Båt & Fartyg, Tillverkning",
      },
      {
        url: "/bransch/cementtillverkning/1/118",
        label: "Cementtillverkning",
      },
      {
        url: "/bransch/cyklar-invalidfordon-tillverkning/1/156",
        label: "Cyklar & Invalidfordon, Tillverkning",
      },
      {
        url: "/bransch/datorer-kringutrustning-tillverkning/1/133",
        label: "Datorer & Kringutrustning, Tillverkning",
      },
      {
        url: "/bransch/dorrar-av-tra-tillverkning/1/79",
        label: "Dörrar Av Trä, Tillverkning",
      },
      {
        url: "/bransch/elapparatur-ovrig-tillverkning/1/144",
        label: "Elapparatur Övrig, Tillverkning",
      },
      {
        url: "/bransch/eldfasta-produkter/1/114",
        label: "Eldfasta Produkter",
      },
      {
        url: "/bransch/elektriska-hushallsmaskiner-tillverkning/1/143",
        label: "Elektriska Hushållsmaskiner, Tillverkning",
      },
      {
        url: "/bransch/elektriska-komponenter-kretskort-tillverkning/1/132",
        label: "Elektriska Komponenter & Kretskort, Tillverkning",
      },
      {
        url: "/bransch/elektronikindustri/1/136",
        label: "Elektronikindustri",
      },
      {
        url: "/bransch/eteriska-oljor-tillverkning/1/102",
        label: "Eteriska Oljor, Tillverkning",
      },
      {
        url: "/bransch/fabriksblandad-betongtillverkning/1/122",
        label: "Fabriksblandad Betongtillverkning",
      },
      {
        url: "/bransch/farmaceutiska-basprodukter-tillverkning/1/104",
        label: "Farmaceutiska Basprodukter, Tillverkning",
      },
      {
        url: "/bransch/fibercementvarutillverkning/1/124",
        label: "Fibercementvarutillverkning",
      },
      {
        url: "/bransch/farg-lack-tryckfarg-tillverkning/1/97",
        label: "Färg, Lack & Tryckfärg, Tillverkning",
      },
      {
        url: "/bransch/fargamnen-tillverkning/1/90",
        label: "Färgämnen, Tillverkning",
      },
      {
        url: "/bransch/fonster-av-tra-tillverkning/1/80",
        label: "Fönster Av Trä, Tillverkning",
      },
      {
        url: "/bransch/forvarvsarbete-i-hushall/1/581",
        label: "Förvärvsarbete I Hushåll",
      },
      {
        url: "/bransch/garntillverkning/1/67",
        label: "Garntillverkning",
      },
      {
        url: "/bransch/gipsvarutillverkning/1/121",
        label: "Gipsvarutillverkning",
      },
      { url: "/bransch/gjutning/1/128", label: "Gjutning" },
      {
        url: "/bransch/glasfibertillverkning/1/113",
        label: "Glasfibertillverkning",
      },
      {
        url: "/bransch/glas-glasvarutillverkning/1/112",
        label: "Glas- & Glasvarutillverkning",
      },
      {
        url: "/bransch/grafisk-produktion/1/85",
        label: "Grafisk Produktion",
      },
      {
        url: "/bransch/gummivaror-tillverkning/1/106",
        label: "Gummivaror, Tillverkning",
      },
      {
        url: "/bransch/godsel-kvaveprodukter-tillverkning/1/93",
        label: "Gödsel- & Kväveprodukter, Tillverkning",
      },
      {
        url: "/bransch/hemelektronik-tillverkning/1/135",
        label: "Hemelektronik, Tillverkning",
      },
      {
        url: "/bransch/industrigasframstallning/1/89",
        label: "Industrigasframställning",
      },
      {
        url: "/bransch/jord-skogsbruksmaskiner-tillverkning/1/148",
        label: "Jord- & Skogsbruksmaskiner, Tillverkning",
      },
      {
        url: "/bransch/jarnmalmsutvinning/1/44",
        label: "Järnmalmsutvinning",
      },
      {
        url: "/bransch/kabeltillbehor-tillverkning/1/141",
        label: "Kabeltillbehör, Tillverkning",
      },
      {
        url: "/bransch/kalk-gipstillverkning/1/119",
        label: "Kalk & Gipstillverkning",
      },
      {
        url: "/bransch/kemiska-produkter-tillverkning/1/99",
        label: "Kemiska Produkter, Tillverkning",
      },
      {
        url: "/bransch/keramiska-golv-vaggplattor/1/115",
        label: "Keramiska Golv- & Väggplattor",
      },
      {
        url: "/bransch/keramiska-produkter-tillverkning/1/117",
        label: "Keramiska Produkter, Tillverkning",
      },
      {
        url: "/bransch/klader-textilier-tillverkning/1/69",
        label: "Kläder & Textilier, Tillverkning",
      },
      {
        url: "/bransch/kommunikationsutrustning-tillverkning/1/134",
        label: "Kommunikationsutrustning, Tillverkning",
      },
      {
        url: "/bransch/konstfiber-tillverkning/1/103",
        label: "Konstfiber, Tillverkning",
      },
      {
        url: "/bransch/kontorsmaskiner-tillverkning/1/147",
        label: "Kontorsmaskiner, Tillverkning",
      },
      {
        url: "/bransch/kontors-butiksinred-tillverkning/1/159",
        label: "Kontors- & Butiksinred, Tillverkning",
      },
      {
        url: "/bransch/kontors-butiksmober-tillverkning/1/158",
        label: "Kontors- & Butiksmöber, Tillverkning",
      },
      {
        url: "/bransch/koksinredningar-tillverkning/1/161",
        label: "Köksinredningar, Tillverkning",
      },
      {
        url: "/bransch/koksmobler-tillverkning/1/160",
        label: "Köksmöbler, Tillverkning",
      },
      {
        url: "/bransch/lim-tillverkning/1/101",
        label: "Lim, Tillverkning",
      },
      {
        url: "/bransch/lader-skinnklader-tillverkning/1/71",
        label: "Läder- & Skinnkläder, Tillverkning",
      },
      {
        url: "/bransch/lakemedel-tillverkning/1/105",
        label: "Läkemedel, Tillverkning",
      },
      {
        url: "/bransch/madrasser-tillverkning/1/162",
        label: "Madrasser, Tillverkning",
      },
      {
        url: "/bransch/malmutvinning-ovrig/1/46",
        label: "Malmutvinning, Övrig",
      },
      {
        url: "/bransch/maskiner-tillverkning/1/146",
        label: "Maskiner, Tillverkning",
      },
      {
        url: "/bransch/mattor-tillverkning/1/70",
        label: "Mattor, Tillverkning",
      },
      {
        url: "/bransch/medicin-dentalinstrumenttillverkning/1/170",
        label: "Medicin- & Dentalinstrumenttillverkning",
      },
      {
        url: "/bransch/metallindustri/1/127",
        label: "Metallindustri",
      },
      {
        url: "/bransch/militara-stridsfordon-tillverkning/1/154",
        label: "Militära Stridsfordon, Tillverkning",
      },
      {
        url: "/bransch/mineralutvinning-ovrig/1/47",
        label: "Mineralutvinning, Övrig",
      },
      {
        url: "/bransch/motorcyklar-tillverkning/1/155",
        label: "Motorcyklar, Tillverkning",
      },
      {
        url: "/bransch/motorer-turbiner-tillverkning/1/145",
        label: "Motorer & Turbiner, Tillverkning",
      },
      {
        url: "/bransch/motorfordonstillverkning/1/150",
        label: "Motorfordonstillverkning",
      },
      {
        url: "/bransch/murbrukstillverkning/1/123",
        label: "Murbrukstillverkning",
      },
      {
        url: "/bransch/musikinstrumenttillverkning/1/167",
        label: "Musikinstrumenttillverkning",
      },
      {
        url: "/bransch/mynttillverkning/1/164",
        label: "Mynttillverkning",
      },
      {
        url: "/bransch/mobler-ovriga-tillverkning/1/163",
        label: "Möbler Övriga, Tillverkning",
      },
      {
        url: "/bransch/oorganiska-baskemikalier-tillverkning/1/91",
        label: "Oorganiska Baskemikalier, Tillverkning",
      },
      {
        url: "/bransch/optiska-fiberkabeltillverkning/1/140",
        label: "Optiska Fiberkabeltillverkning",
      },
      {
        url: "/bransch/optiska-instrument-fotoutrustning/1/138",
        label: "Optiska Instrument & Fotoutrustning",
      },
      {
        url: "/bransch/organiska-baskemikalier-tillverkning/1/92",
        label: "Organiska Baskemikalier, Tillverkning",
      },
      {
        url: "/bransch/pappers-pappersvarutillverkning/1/81",
        label: "Pappers- & Pappersvarutillverkning",
      },
      {
        url: "/bransch/petroleumraffinering/1/88",
        label: "Petroleumraffinering",
      },
      { url: "/bransch/planglas/1/111", label: "Planglas" },
      {
        url: "/bransch/plastforpackningstillverkning/1/108",
        label: "Plastförpackningstillverkning",
      },
      {
        url: "/bransch/plastvaror-tillverkning/1/107",
        label: "Plastvaror, Tillverkning",
      },
      {
        url: "/bransch/plastvarutillverkning-ovrig/1/110",
        label: "Plastvarutillverkning, Övrig",
      },
      {
        url: "/bransch/palsvaror-tillverkning/1/73",
        label: "Pälsvaror, Tillverkning",
      },
      {
        url: "/bransch/rapetroleumutvinning/1/42",
        label: "Råpetroleumutvinning",
      },
      {
        url: "/bransch/ralsfordon-tillverkning/1/153",
        label: "Rälsfordon, Tillverkning",
      },
      {
        url: "/bransch/skor-tillverkning/1/75",
        label: "Skor, Tillverkning",
      },
      {
        url: "/bransch/smyckestillverkning/1/165",
        label: "Smyckestillverkning",
      },
      {
        url: "/bransch/spel-leksakstillverkning/1/169",
        label: "Spel- & Leksakstillverkning",
      },
      {
        url: "/bransch/sportartikelstillverkning/1/168",
        label: "Sportartikelstillverkning",
      },
      {
        url: "/bransch/sprangamne-tillverkning/1/100",
        label: "Sprängämne, Tillverkning",
      },
      { url: "/bransch/stenhuggning/1/126", label: "Stenhuggning" },
      {
        url: "/bransch/strumpor-tillverkning/1/74",
        label: "Strumpor, Tillverkning",
      },
      {
        url: "/bransch/syntetiskt-basgummi-tillverkning/1/95",
        label: "Syntetiskt Basgummi, Tillverkning",
      },
      {
        url: "/bransch/sagning-hyvling-impregnering/1/76",
        label: "Sågning, Hyvling & Impregnering",
      },
      {
        url: "/bransch/tandprotestillverkning/1/171",
        label: "Tandprotestillverkning",
      },
      {
        url: "/bransch/tillverkning-ovrig/1/173",
        label: "Tillverkning, Övrig",
      },
      { url: "/bransch/torvutvinning/1/48", label: "Torvutvinning" },
      {
        url: "/bransch/transportmedel-ovriga-tillverkning/1/157",
        label: "Transportmedel Övriga, Tillverkning",
      },
      {
        url: "/bransch/transportmedelsindustri/1/151",
        label: "Transportmedelsindustri",
      },
      {
        url: "/bransch/tryckning-av-bocker-ovrigt/1/84",
        label: "Tryckning Av Böcker & Övrigt",
      },
      {
        url: "/bransch/tryckning-av-dagstidningar/1/82",
        label: "Tryckning Av Dagstidningar",
      },
      {
        url: "/bransch/tryckning-av-tidsskrifter/1/83",
        label: "Tryckning Av Tidsskrifter",
      },
      {
        url: "/bransch/trahus-tillverkning/1/78",
        label: "Trähus, Tillverkning",
      },
      {
        url: "/bransch/travaror-tillverkning/1/77",
        label: "Trävaror, Tillverkning",
      },
      {
        url: "/bransch/tval-sapa-tvattmedel-tillverkning/1/98",
        label: "Tvål, Såpa & Tvättmedel, Tillverkning",
      },
      {
        url: "/bransch/uran-toriummalmutvinning/1/45",
        label: "Uran- & Toriummalmutvinning",
      },
      {
        url: "/bransch/urtillverkning/1/137",
        label: "Urtillverkning",
      },
      {
        url: "/bransch/utvinning-stodtjanster/1/50",
        label: "Utvinning, Stödtjänster",
      },
      {
        url: "/bransch/vapen-ammunition-tillverkning/1/130",
        label: "Vapen & Ammunition, Tillverkning",
      },
      {
        url: "/bransch/verktyg-redskap-tillverkning/1/131",
        label: "Verktyg & Redskap, Tillverkning",
      },
      {
        url: "/bransch/verktygsmaskiner-tillverkning/1/149",
        label: "Verktygsmaskiner, Tillverkning",
      },
      {
        url: "/bransch/vavnadstillverkning/1/68",
        label: "Vävnadstillverkning",
      },
    ],
  },
  {
    name: "Transport",
    operations: [
      {
        url: "/bransch/transport-magasinering/10/_",
        label: "Se allt inom Transport & Magasinering",
      },
      { url: "/bransch/bargning/10/354", label: "Bärgning" },
      { url: "/bransch/flyttjanster/10/346", label: "Flyttjänster" },
      {
        url: "/bransch/godshantering-ovrig/10/359",
        label: "Godshantering, Övrig",
      },
      {
        url: "/bransch/hamngodshantering/10/358",
        label: "Hamngodshantering",
      },
      {
        url: "/bransch/havs-sjofart-godstrafik/10/349",
        label: "Havs- & Sjöfart, Godstrafik",
      },
      {
        url: "/bransch/havs-sjofart-passagerartrafik/10/348",
        label: "Havs- & Sjöfart, Passagerartrafik",
      },
      {
        url: "/bransch/jarnvagstransport-passagerartrafik/10/339",
        label: "Järnvägstransport- Passagerartrafik",
      },
      {
        url: "/bransch/jarnvagstransport-godstrafik/10/340",
        label: "Järnvägstransport-Godstrafik",
      },
      {
        url: "/bransch/kollektivtrafik-ovrig/10/342",
        label: "Kollektivtrafik, Övrig",
      },
      {
        url: "/bransch/landtransport-av-passagerare-ovrig/10/344",
        label: "Landtransport Av Passagerare, Övrig",
      },
      {
        url: "/bransch/landtransport-stodtjanster/10/355",
        label: "Landtransport, Stödtjänster",
      },
      {
        url: "/bransch/linjebussverksamhet/10/341",
        label: "Linjebussverksamhet",
      },
      {
        url: "/bransch/lufttransport-godstrafik/10/351",
        label: "Lufttransport, Godstrafik",
      },
      {
        url: "/bransch/lufttransport-passagerartrafik/10/350",
        label: "Lufttransport, Passagerartrafik",
      },
      {
        url: "/bransch/lufttransporter-stodtjanster/10/357",
        label: "Lufttransporter, Stödtjänster",
      },
      {
        url: "/bransch/magasinering-varulagring/10/353",
        label: "Magasinering & Varulagring",
      },
      { url: "/bransch/rymdfart/10/352", label: "Rymdfart" },
      { url: "/bransch/rorsystem/10/347", label: "Rörsystem" },
      {
        url: "/bransch/sjotransport-stodtjanster/10/356",
        label: "Sjötransport, Stödtjänster",
      },
      { url: "/bransch/taxi/10/343", label: "Taxi" },
      {
        url: "/bransch/transport-stodtjanster-ovriga/10/360",
        label: "Transport Stödtjänster, Övriga",
      },
      {
        url: "/bransch/vagtransport-godstrafik/10/345",
        label: "Vägtransport, Godstrafik",
      },
    ],
  },
  {
    name: "Utbildning",
    operations: [
      {
        url: "/bransch/utbildning-forskning-utveckling/19/_",
        label: "Se allt inom Utbildning, Forskning & Utveckling",
      },
      {
        url: "/bransch/arbetsmarknadsutbildning/19/525",
        label: "Arbetsmarknadsutbildning",
      },
      {
        url: "/bransch/bioteknisk-forskning-utveckling/19/447",
        label: "Bioteknisk Forskning & Utveckling",
      },
      {
        url: "/bransch/eftergymnasial-utbildning/19/520",
        label: "Eftergymnasial Utbildning",
      },
      {
        url: "/bransch/folkhogskoleutbildning/19/526",
        label: "Folkhögskoleutbildning",
      },
      {
        url: "/bransch/forskoleutbildning/19/516",
        label: "Förskoleutbildning",
      },
      {
        url: "/bransch/grundskoleutbildning/19/517",
        label: "Grundskoleutbildning",
      },
      {
        url: "/bransch/gymnasial-utbildning/19/518",
        label: "Gymnasial Utbildning",
      },
      {
        url: "/bransch/musik-dans-kulturell-utbildning/19/523",
        label: "Musik-, Dans- & Kulturell Utbildning",
      },
      {
        url: "/bransch/naturvetenskaplig-och-teknisk-fu/19/448",
        label: "Naturvetenskaplig Och Teknisk F&U",
      },
      {
        url: "/bransch/personalutbildning/19/528",
        label: "Personalutbildning",
      },
      {
        url: "/bransch/samhallsvet-humanistisk-fu/19/449",
        label: "Samhällsvet. & Humanistisk F&U",
      },
      {
        url: "/bransch/sport-fritidsutbildning/19/522",
        label: "Sport- & Fritidsutbildning",
      },
      {
        url: "/bransch/studieforbundens-frivilligorg-utbildning/19/527",
        label: "Studieförbundens & Frivilligorg. Utbildning",
      },
      {
        url: "/bransch/trafikskoleverksamhet/19/524",
        label: "Trafikskoleverksamhet",
      },
      {
        url: "/bransch/universitets-hogskoleutbildning/19/521",
        label: "Universitets- & Högskoleutbildning",
      },
      {
        url: "/bransch/utbildning-ovrig/19/529",
        label: "Utbildning, Övrig",
      },
      {
        url: "/bransch/utbildningsvasendet-stodverksamhet/19/530",
        label: "Utbildningsväsendet, Stödverksamhet",
      },
      {
        url: "/bransch/yrkesforarutbildning/19/519",
        label: "Yrkesförarutbildning",
      },
      {
        url: "/bransch/oversattning-tolkning/19/459",
        label: "Översättning & Tolkning",
      },
    ],
  },
  {
    name: "Uthyrning",
    operations: [
      {
        url: "/bransch/uthyrning-leasing/22/_",
        label: "Se allt inom Uthyrning & Leasing",
      },
      {
        url: "/bransch/leasing-av-immateriell-egendom-liknande-prod/22/473",
        label: "Leasing Av Immateriell Egendom & Liknande Prod.",
      },
      {
        url: "/bransch/uthyrning-av-videokassetter-dvd-skivor/22/465",
        label: "Uthyrning Av Videokassetter & Dvd-Skivor",
      },
      {
        url: "/bransch/uthyrning-leasing-av-andra-hushallsartiklar-varor-for-personligt-bruk/22/466",
        label:
          " Uthyrning & Leasing Av Andra Hushållsartiklar & Varor För Personligt Bruk",
      },
      {
        url: "/bransch/uthyrning-leasing-av-bygg-anlaggningsmaskiner/22/468",
        label: " Uthyrning & Leasing Av Bygg- & Anläggningsmaskiner",
      },
      {
        url: "/bransch/uthyrning-leasing-av-fartyg-batar/22/470",
        label: "Uthyrning & Leasing Av Fartyg & Båtar",
      },
      {
        url: "/bransch/uthyrning-leasing-av-flygplan/22/471",
        label: "Uthyrning & Leasing Av Flygplan",
      },
      {
        url: "/bransch/uthyrning-leasing-av-fritids-sportutrustning/22/464",
        label: "Uthyrning & Leasing Av Fritids- & Sportutrustning",
      },
      {
        url: "/bransch/uthyrning-leasing-av-jordbruksmaskiner-jordbruksredskap/22/467",
        label: " Uthyrning & Leasing Av Jordbruksmaskiner & Jordbruksredskap",
      },
      {
        url: "/bransch/uthyrning-leasing-av-kontorsmaskiner-kontorsutrustning-inklusive-datorer/22/469",
        label: " Uthyrning & Leasing Av Kontorsmaskiner & Kontorsutrustning",
      },
      {
        url: "/bransch/uthyrning-leasing-av-lastbilar-andra-tunga-motorfordon/22/463",
        label: " Uthyrning & Leasing Av Lastbilar & Andra Tunga Motorfordon",
      },
      {
        url: "/bransch/uthyrning-leasing-av-personbilar-latta-motorfordon/22/462",
        label: " Uthyrning & Leasing Av Personbilar & Lätta Motorfordon",
      },
      {
        url: "/bransch/uthyrning-leasing-ovrigt/22/472",
        label: "Uthyrning & Leasing Övrigt",
      },
    ],
  },
  {
    name: "Ovriga",
    operations: [
      {
        url: "/bransch/ovriga-konsumenttjanster/20/_",
        label: "Se allt inom Övriga Konsumenttjänster",
      },
      {
        url: "/bransch/begravningsverksamhet/20/578",
        label: "Begravningsverksamhet",
      },
      {
        url: "/bransch/konsumenttjanster-ovriga/20/580",
        label: "Konsumenttjänster, Övriga",
      },
      { url: "/bransch/kroppsvard/20/579", label: "Kroppsvård" },
      {
        url: "/bransch/portrattfotoverksamhet/20/455",
        label: "Porträttfotoverksamhet",
      },
      {
        url: "/bransch/tvatteriverksamhet/20/575",
        label: "Tvätteriverksamhet",
      },
    ],
  },
];

export const OperationOptions = [
  "Ambassader & Internationella Org.",
  "Avfallshantering & Återvinning",
  "Avloppsrening",
  "Eldistribution",
  "Elgenerering",
  "Elhandel",
  "Elöverföring",
  "Farligt Avfall",
  "Gasdistribution",
  "Gasframställning",
  "Gashandel",
  "Icke-Farligt Avfall",
  "Källsorterat Material",
  "Vatten & Avlopp",
  "Värme & Kyla",
  "Centralbanksverksamhet",
  "Finans, Administrativa Tjänster",
  "Finansförmedling, Övrig",
  "Finansiell Leasing",
  "Finansiella Stödtjänster, Övriga",
  "Fondanknuten Livförsäkring",
  "Fonder & Liknande Finansiella Enheter, Övriga",
  "Fondförvaltning, Övrig",
  "Försäkring & Pensionsfond Stödtjänster, Övriga",
  "Förvaltning Av Investeringsfonder",
  "Förvaltning & Handel Med Värdepapper",
  "Holdingverksamhet I Finansiella Koncerner",
  "Holdingverksamhet I Icke-Finansiella Koncerner",
  "Investeringsfonder",
  "Investment- & Riskkapitalbolag",
  "Kreditgivning, Övrig",
  "Livförsäkring, Övrig",
  "Monetär Finansförmedling, Övrig",
  "Pensionsfondsverksamhet",
  "Risk- & Skadebedömning",
  "Skadeförsäkring",
  "Verksamhet Utförd Av Försäkringsombud & Försäkringsmäklare",
  "Verksamhet Utförd Av Värdepappers- & Varumäklare",
  "Återförsäkring",
  "Arbetsförmedling & Rekrytering",
  "Personalfunktioner, Övrigt",
  "Personaluthyrning",
  "Intressebev. Arbetsgivarorg.",
  "Intressebev. Branschorg.",
  "Intressorganisationer, övriga",
  "Intressebev. Arbetstagarorg.",
  "Religiösa samfund",
  "Intressebev. Yrkesorg.",
  "Politiska organisationer",
  "Byggverksamhet",
  "Takarbeten",
  "Anläggningsarbeten",
  "El-VVS & Bygginstallationer",
  "Industri- & Produktdesignverksamhet",
  "Teknisk konsult inom Bygg- & Anläggningsteknik",
  "Utformning av Byggprojekt",
  "Måleriarbeten",
  "Uthyrning av Bygg- & Anläggningsmaskiner med förare",
  "Byggnadssnickeriarbeten",
  "Grafisk Designverksamhet",
  "Arkitektverksamhet",
  "Glasmästeriarbeten",
  "Golv- & Väggbeläggningsarbeten",
  "Puts-, Fasad- & Stuckatörsarbeten",
  "Markundersökning",
  "Inredningsarkitekt",
  "Rivning av hus & byggnader",
  "Slutbehandling av byggnader",
  "Datakonsultverksamhet",
  "Dataprogrammering",
  "Datordrifttjänster",
  "It- & Datatjänster, Övriga",
  "Telekommunikation, Satellit",
  "Telekommunikation, Trådbunden",
  "Telekommunikation, Trådlös",
  "Telekommunikation, Övrig",
  "Utgivning Av Annan Programvara",
  "Utgivning Av Dataspel",
  "Alkoholhaltiga Drycker, Butikshandel",
  "Antikviteter & Beg. Böcker, Butikshandel",
  "Apotekshandel",
  "Auktioner",
  "Band & Skivor, Butikshandel",
  "Barnkläder, Butikshandel",
  "Begagnade Varor Övriga, Butikshandel",
  "Belysningsartiklar, Butikshandel",
  "Blommor & Växter, Butikshandel",
  "Bröd & Konditori, Butikshandel",
  "Butikshandel, Övrig",
  "Båtar, Butikshandel",
  "Böcker, Butikshandel",
  "Cyklar, Butikshandel",
  "Damkläder, Butikshandel",
  "Datorer, Program, Data-& Tv-Spel, Butikshandel",
  "Detaljhandel, Övrig",
  "Drivmedel, Detaljhandel",
  "Elektriska Hushållsmaskiner, Butikshandel",
  "Fisk & Skaldjur, Butikshandel",
  "Fotoutrustning, Butikshandel",
  "Frukt & Grönsaker, Butikshandel",
  "Färg & Lack, Butikshandel",
  "Glas & Porslin, Butikshandel",
  "Guldsmedsvaror & Smycken, Butikshandel",
  "Herrkläder, Butikshandel",
  "Hälsokost, Butikshandel",
  "Inredningstextilier, Butikshandel",
  "Järn- & Vvs- Varor, Butikshandel",
  "Klockor, Butikshandel",
  "Kläder, Butikshandel",
  "Konfektyr, Butikshandel",
  "Konst & Galleri, Butikshandel",
  "Kontorsförbrukningsvaror, Butikshandel",
  "Kontorsmöbler, Butikshandel",
  "Kosmetika & Hygienartiklar, Butikshandel",
  "Kött & Charkuterier, Butikshandel",
  "Livsmedel Övriga, Butikshandel",
  "Livsmedelshandel",
  "Ljud & Bild, Butikshandel",
  "Mattor & Väggbeklädnad, Butikshandel",
  "Musikinstrument & Noter, Butikshandel",
  "Mynt & Frimärken, Butikshandel",
  "Möbler För Hemmet, Butikshandel",
  "Optiker, Butikshandel",
  "Postorder- & Internethandel",
  "Pälsar, Butikshandel",
  "Sjukvårdsartiklar, Butikshandel",
  "Skor, Butikshandel",
  "Spel & Leksaker, Butikshandel",
  "Sport- & Fritidsartiklar, Butikshandel",
  "Sällskapsdjur, Butikshandel",
  "Telekommunikationsutrustning, Butikshandel",
  "Textilier, Butikshandel",
  "Tidningar, Butikshandel",
  "Tobaksvaror, Butikshandel",
  "Torg- & Marknadshandel",
  "Varuhus- & Stormarknadshandel",
  "Virke & Byggvaror, Butikshandel",
  "Väskor & Reseffekter, Butikshandel",
  "Fastighetsförmedling",
  "Fastighetsförvaltning På Uppdrag",
  "Fastighetsrelaterade Stödtjänster",
  "Förvaltning I Bostadsrättsföreningar",
  "Handel Med Egna Fastigheter",
  "Rengöring & Lokalvård",
  "Skötsel & Underhåll Av Grönytor",
  "Uthyrning & Förvaltning Av Fastigheter",
  "Bud- & Kurirverksamhet",
  "Callcenterverksamhet",
  "Databehandling & Hosting",
  "Fotolaboratorieverksamhet",
  "Företagstjänster, Övriga",
  "Förpackningsverksamhet",
  "Informationstjänster, Övriga",
  "Inkasso- & Kreditupplysningsverksamhet",
  "Kongresser & Mässor",
  "Kontorstjänster",
  "Nyhetsservice",
  "Post- & Kurirverksamhet",
  "Press- & Övrig Fotografverksamhet",
  "Reklamfotoverksamhet",
  "Spanings- & Detektivverksamhet",
  "Säkerhetssystemtjänster",
  "Säkerhetsverksamhet",
  "Tidningsdistribution",
  "Verksamheter Som Utövas Av Huvudkontor",
  "Webbportaler",
  "Campingplatsverksamhet",
  "Catering",
  "Centralkök För Sjukhus",
  "Centralkök För Skolor & Omsorg",
  "Hotell & Restaurang",
  "Konferensanläggningar",
  "Logiverksamhet, Övrig",
  "Personalmatsalar",
  "Restaurangverksamhet",
  "Stugbyverksamhet",
  "Vandrarhemsverksamhet",
  "Frisörer",
  "Skönhetsvård",
  "Hälso- & Sjukvård, Övriga",
  "Primärvårdsmottagning",
  "Sluten Sjukvård",
  "Sociala Insatser",
  "Specialistläkare Ej På Sjukhus",
  "Specialistläkare På Sjukhus",
  "Tandläkare",
  "Veterinärverksamhet",
  "Vård & Omsorg Med Boende",
  "Öppen Hälso- & Sjukvård",
  "Blandat Jordbruk",
  "Djuruppfödning, Övrig",
  "Drivning",
  "Fiberväxtodling",
  "Fiskodling",
  "Fjäderfä, Uppfödning",
  "Frukt, Bär & Nötter, Odling",
  "Fruktodling",
  "Får- & Getuppfödning",
  "Grönsaker, Frilandsodling",
  "Grönsaker, Växthusodling",
  "Hästuppfödning",
  "Jakt",
  "Kamel- & Kameldjursuppfödning",
  "Mjölkproduktion & Nötkreatursuppfödning",
  "Nötkreatur & Bufflar, Övriga",
  "Plantskoleväxter, Odling",
  "Potatisodling",
  "Renskötsel",
  "Saltvattensfiske",
  "Service Till Husdjursskötsel",
  "Service Till Skogsbruk",
  "Service Till Växtodling",
  "Skogsbruk",
  "Skogsförvaltning",
  "Skogsskötsel",
  "Slaktsvinuppfödning",
  "Smågrisuppfödning",
  "Socketbetsodling",
  "Spannmål, Balj- & Oljeväxter, Odling",
  "Svampodling",
  "Sällskapsdjur, Uppfödning",
  "Sötvattensfiske",
  "Tobaksodling",
  "Virkesmätning",
  "Växter, Bearbetning",
  "Växtodling",
  "Äggproduktion",
  "Advokatbyråer",
  "Juridisk Verksamhet, Övrig",
  "Konsultverksamhet Avseende Företags Org.",
  "Patentbyråer",
  "Redovisning & Bokföring",
  "Revision",
  "Skatterådgivning",
  "Arkivverksamhet",
  "Artistisk Verksamhet",
  "Bibliotek",
  "Fritids-& Nöjesverksamhet, Övrig",
  "Golfbanor",
  "Gymanläggningar",
  "Idrottsplatser & Sportanläggningar",
  "Kultur, Nöje & Fritid",
  "Litterärt & Konstnärligt Skapande",
  "Motorbanor",
  "Museiverksamhet",
  "Nöjes- & Temaparksverksamhet",
  "Skidsportanläggningar",
  "Spel- & Vadhållningsverksamhet",
  "Sportklubbars & Idrottsför. Verksamhet",
  "Sportverksamhet, Övrig",
  "Trav- & Galoppbanor",
  "Trädgårdar, Djurparker & Naturreservat, Drift",
  "Tävling Med Hästar",
  "Vård Av Historiska Minnesmärken & Byggnader",
  "Bageri- & Mjölprodukter",
  "Choklad- & Konfektyrtillverkning",
  "Djurfoderframställning",
  "Dryckesframställning",
  "Fisk, Skaldjur & Blötdjur, Beredning",
  "Glasstillverkning",
  "Juice & Safttillverkning",
  "Kvarnprodukter",
  "Köttprodukter",
  "Livsmedelsframställning",
  "Mejerivarutillverkning",
  "Potatisberedning",
  "Sockertillverkning",
  "Stärkelsetillverkning",
  "Te- & Kaffetillverkning",
  "Tobaksvarutillverkning",
  "Annonstidningsutgivning",
  "Bokutgivning",
  "Dagstidningsutgivning",
  "Film, Video & Tv",
  "Filmvisning",
  "Förlagsverksamhet, Övrig",
  "Ljudinspelning & Fonogramutgivning",
  "Publicering Av Kataloger & Sändlistor",
  "Radiosändning",
  "Reproduktion Av Inspelningar",
  "Tv-Program Planering",
  "Utgivning Av Tidskrifter",
  "Husvagnar, Husbilar & Släp, Handel",
  "Lastbilar, Bussar & Specialfordon, Handel",
  "Personbilar & Lätta Motorfordon, Handel",
  "Arbetsmarknadsprogram",
  "Brand- & Räddningsverksamhet",
  "Civilt Försvar & Frivilligförsvar",
  "Domstolsverksamhet",
  "Grundskole- & Gymnasieskoleutbildning",
  "Hälso- & Sjukvård, Administration",
  "Infrastrukturprogram",
  "Inspektion, Kontroll & Tillståndsgivning",
  "Jord- & Skogsbruk, Administration Av Program",
  "Kriminalvård",
  "Kultur, Miljö, Boende, Administration",
  "Militärt Försvar",
  "Näringslivsprogram, Övriga",
  "Obligatorisk Socialförsäkring",
  "Omsorg & Socialtjänst",
  "Personalförvaltning & Andra Stödtjänster",
  "Polisverksamhet",
  "Samhällelig Informationsförsörjning",
  "Skatteförvaltning & Indrivning",
  "Stats- & Kommunledning, Lagstiftning & Planering",
  "Totalförsvaret",
  "Universitets- & Högskoleutbildning Samt Forskning",
  "Utrikesförvaltning",
  "Åklagarverksamhet",
  "Avfall & Skrot, Partihandel",
  "Band & Skivor, Partihandel",
  "Blandat Sortiment",
  "Blommor & Växter, Partihandel",
  "Bränsle, Mineraler & Industrikem. Partihandel",
  "Datorer, Program & Kringutr, Partihandel",
  "Datoriserad Materialhanteringsutr, Partihandel",
  "Drycker, Partihandel",
  "Elartiklar, Partihandel",
  "Elektronikkomponenter, Partihandel",
  "Emballage, Partihandel",
  "Fisk, Skalddjur & Andra Livsmedel, Partihandel",
  "Fotografiska & Optiska Produkter, Partihandel",
  "Frukt & Grönsaker, Partihandel",
  "Glas, Porslin & Rengöringsmedel, Partihandel",
  "Gruv-, Bygg- & Anläggningsmaskiner, Partihandel",
  "Hudar, Skinn & Läder, Partihandel",
  "Hushållsapparater & Elartiklar, Partihandel",
  "Hushållsvaror Övriga, Partihandel",
  "Icke Spec. Handel Med Livsmedel, Partihandel",
  "Industriförnödenheter, Partihandel",
  "Insatsvaror Övriga, Partihandel",
  "Jordbruksmaskiner, Partihandel",
  "Jordbruks- & Textilråvaror, Provisionshandel",
  "Järnhandelsvaror, Partihandel",
  "Kaffe, Te, Kakao & Kryddor, Partihandel",
  "Kemiska Produkter, Partihandel",
  "Kläder & Skor, Partihandel",
  "Kontorsförbrukningsvaror, Partihandel",
  "Kontorsmaskiner & Kontorsutrustning, Partihandel",
  "Kontorsmöbler, Partihandel",
  "Kontorsutrustning & Datorer, Partihandel",
  "Kött & Köttvaror, Partihandel",
  "Levande Djur, Partihandel",
  "Livsmedel, Dryck & Tobak, Partihandel",
  "Ljud, Bild & Videoutrustning, Partihandel",
  "Maskiner & Utrustning Övriga, Partihandel",
  "Maskiner, Industriell Utrustning, Partihandel",
  "Medicinsk Utrustning & Apoteksvaror, Partihandel",
  "Mejeriprodukter, Ägg, Matolja & Matfett, Partihandel",
  "Metallavfall & Metallskrot, Partihandel",
  "Metaller & Metallmalmer, Partihandel",
  "Motorcyklar, Handel, Service & Tillbehör",
  "Motorfordon, Reservdelar & Tillbehör",
  "Mät- & Precisionsinstrument, Partihandel",
  "Möbler, Hushålls- & Järnhandelsvaror, Partihandel",
  "Möbler, Mattor & Belysning, Partihandel",
  "Parfym & Kosmetika, Partihandel",
  "Partihandel",
  "Partihandel, Övrig",
  "Sanitetsgods, Partihandel",
  "Socker, Choklad & Sockerkonfekt, Partihandel",
  "Specialsortiment",
  "Sport- & Fritidsartiklar, Partihandel",
  "Teleprodukter, Partihandel",
  "Textilier, Kläder & Skodon, Partihandel",
  "Textilier, Partihandel",
  "Textil-, Sy- & Stickmaskiner, Partihandel",
  "Tobak, Partihandel",
  "Ur & Guldssmedsvaror, Partihandel",
  "Uttjänta Fordon, Partihandel",
  "Verktygsmaskiner, Partihandel",
  "Virke & Byggmaterial, Partihandel",
  "Vvs-Varor, Partihandel",
  "Direktreklamverksamhet",
  "Marknads- & Opinionsundersökning",
  "Reklam, Pr, Mediebyrå & Annonsförsälj.",
  "Datorer & Kringutrustning, Reparation",
  "Elapparatur, Reparation",
  "Elektronisk & Optisk Utrustning, Reparation",
  "Fartyg & Båtar, Reparation",
  "Hemelektronik, Reparation",
  "Hushållsapparater, Hem & Trädgård, Reparation",
  "Hushålls- & Personartiklar, Reparation, Övriga",
  "Industri- Maskiner & Utrustning, Installation",
  "Kommunikationsutrustning, Reparation",
  "Luftfartyg & Rymdfarkoster, Reparation",
  "Maskiner, Reparation",
  "Metallvaror, Reparation",
  "Motorfordon, Reparation & Underhåll",
  "Möbler & Heminredning, Reparation",
  "Skomakare",
  "Transportmedel Övriga, Reparation",
  "Ur & Guldsmedsvaror, Reparation",
  "Utrustning Reparation, Övrig",
  "Researrangemang",
  "Resebyråer",
  "Turist- & Bokningsservice",
  "Juridik, Ekonomi, Vetenskap & Teknik, Övrig",
  "Teknisk Konsult Inom Elteknik",
  "Teknisk Konsult Inom Energi-, Miljö- & Vvs-Teknik",
  "Teknisk Konsult Inom Industriteknik",
  "Teknisk Konsultverksamhet, Övrig",
  "Teknisk Provning & Analys",
  "Arbets- & Skyddskläder, Tillverkning",
  "Basplast, Tillverkning",
  "Batteri- Och Ackumulatortillverkning",
  "Bekämpningsmedel & Lantbrukskem, Tillverkning",
  "Belysningsarmaturtillverkning",
  "Betongvarutillverkning",
  "Betong-, Cement- & Gipsvaror, Övriga",
  "Bijouteritillverkning",
  "Borstbinderitillverkning",
  "Byggmaterialtillverkning",
  "Byggnadsmetallvaror, Tillverkning",
  "Byggplastvarutillverkning",
  "Båt & Fartyg, Tillverkning",
  "Cementtillverkning",
  "Cyklar & Invalidfordon, Tillverkning",
  "Datorer & Kringutrustning, Tillverkning",
  "Dörrar Av Trä, Tillverkning",
  "Elapparatur Övrig, Tillverkning",
  "Eldfasta Produkter",
  "Elektriska Hushållsmaskiner, Tillverkning",
  "Elektriska Komponenter & Kretskort, Tillverkning",
  "Elektronikindustri",
  "Eteriska Oljor, Tillverkning",
  "Fabriksblandad Betongtillverkning",
  "Farmaceutiska Basprodukter, Tillverkning",
  "Fibercementvarutillverkning",
  "Färg, Lack & Tryckfärg, Tillverkning",
  "Färgämnen, Tillverkning",
  "Fönster Av Trä, Tillverkning",
  "Förvärvsarbete I Hushåll",
  "Garntillverkning",
  "Gipsvarutillverkning",
  "Gjutning",
  "Glasfibertillverkning",
  "Glas- & Glasvarutillverkning",
  "Grafisk Produktion",
  "Gummivaror, Tillverkning",
  "Gödsel- & Kväveprodukter, Tillverkning",
  "Hemelektronik, Tillverkning",
  "Industrigasframställning",
  "Jord- & Skogsbruksmaskiner, Tillverkning",
  "Järnmalmsutvinning",
  "Kabeltillbehör, Tillverkning",
  "Kalk & Gipstillverkning",
  "Kemiska Produkter, Tillverkning",
  "Keramiska Golv- & Väggplattor",
  "Keramiska Produkter, Tillverkning",
  "Kläder & Textilier, Tillverkning",
  "Kommunikationsutrustning, Tillverkning",
  "Konstfiber, Tillverkning",
  "Kontorsmaskiner, Tillverkning",
  "Kontors- & Butiksinred, Tillverkning",
  "Kontors- & Butiksmöber, Tillverkning",
  "Köksinredningar, Tillverkning",
  "Köksmöbler, Tillverkning",
  "Lim, Tillverkning",
  "Läder- & Skinnkläder, Tillverkning",
  "Läkemedel, Tillverkning",
  "Madrasser, Tillverkning",
  "Malmutvinning, Övrig",
  "Maskiner, Tillverkning",
  "Mattor, Tillverkning",
  "Medicin- & Dentalinstrumenttillverkning",
  "Metallindustri",
  "Militära Stridsfordon, Tillverkning",
  "Mineralutvinning, Övrig",
  "Motorcyklar, Tillverkning",
  "Motorer & Turbiner, Tillverkning",
  "Motorfordonstillverkning",
  "Murbrukstillverkning",
  "Musikinstrumenttillverkning",
  "Mynttillverkning",
  "Möbler Övriga, Tillverkning",
  "Oorganiska Baskemikalier, Tillverkning",
  "Optiska Fiberkabeltillverkning",
  "Optiska Instrument & Fotoutrustning",
  "Organiska Baskemikalier, Tillverkning",
  "Pappers- & Pappersvarutillverkning",
  "Petroleumraffinering",
  "Planglas",
  "Plastförpackningstillverkning",
  "Plastvaror, Tillverkning",
  "Plastvarutillverkning, Övrig",
  "Pälsvaror, Tillverkning",
  "Råpetroleumutvinning",
  "Rälsfordon, Tillverkning",
  "Skor, Tillverkning",
  "Smyckestillverkning",
  "Spel- & Leksakstillverkning",
  "Sportartikelstillverkning",
  "Sprängämne, Tillverkning",
  "Stenhuggning",
  "Strumpor, Tillverkning",
  "Syntetiskt Basgummi, Tillverkning",
  "Sågning, Hyvling & Impregnering",
  "Tandprotestillverkning",
  "Tillverkning, Övrig",
  "Torvutvinning",
  "Transportmedel Övriga, Tillverkning",
  "Transportmedelsindustri",
  "Tryckning Av Böcker & Övrigt",
  "Tryckning Av Dagstidningar",
  "Tryckning Av Tidsskrifter",
  "Trähus, Tillverkning",
  "Trävaror, Tillverkning",
  "Tvål, Såpa & Tvättmedel, Tillverkning",
  "Uran- & Toriummalmutvinning",
  "Urtillverkning",
  "Utvinning, Stödtjänster",
  "Vapen & Ammunition, Tillverkning",
  "Verktyg & Redskap, Tillverkning",
  "Verktygsmaskiner, Tillverkning",
  "Vävnadstillverkning",
  "Bärgning",
  "Flyttjänster",
  "Godshantering, Övrig",
  "Hamngodshantering",
  "Havs- & Sjöfart, Godstrafik",
  "Havs- & Sjöfart, Passagerartrafik",
  "Järnvägstransport- Passagerartrafik",
  "Järnvägstransport-Godstrafik",
  "Kollektivtrafik, Övrig",
  "Landtransport Av Passagerare, Övrig",
  "Landtransport, Stödtjänster",
  "Linjebussverksamhet",
  "Lufttransport, Godstrafik",
  "Lufttransport, Passagerartrafik",
  "Lufttransporter, Stödtjänster",
  "Magasinering & Varulagring",
  "Rymdfart",
  "Rörsystem",
  "Sjötransport, Stödtjänster",
  "Taxi",
  "Transport Stödtjänster, Övriga",
  "Vägtransport, Godstrafik",
  "Arbetsmarknadsutbildning",
  "Bioteknisk Forskning & Utveckling",
  "Eftergymnasial Utbildning",
  "Folkhögskoleutbildning",
  "Förskoleutbildning",
  "Grundskoleutbildning",
  "Gymnasial Utbildning",
  "Musik-, Dans- & Kulturell Utbildning",
  "Naturvetenskaplig Och Teknisk F&U",
  "Personalutbildning",
  "Samhällsvet. & Humanistisk F&U",
  "Sport- & Fritidsutbildning",
  "Studieförbundens & Frivilligorg. Utbildning",
  "Trafikskoleverksamhet",
  "Universitets- & Högskoleutbildning",
  "Utbildning, Övrig",
  "Utbildningsväsendet, Stödverksamhet",
  "Yrkesförarutbildning",
  "Översättning & Tolkning",
  "Leasing Av Immateriell Egendom & Liknande Prod.",
  "Uthyrning Av Videokassetter & Dvd-Skivor",
  "Uthyrning & Leasing Av Andra Hushållsartiklar & Varor För Personligt Bruk",
  "Uthyrning & Leasing Av Bygg- & Anläggningsmaskiner",
  "Uthyrning & Leasing Av Fartyg & Båtar",
  "Uthyrning & Leasing Av Flygplan",
  "Uthyrning & Leasing Av Fritids- & Sportutrustning",
  "Uthyrning & Leasing Av Jordbruksmaskiner & Jordbruksredskap",
  "Uthyrning & Leasing Av Kontorsmaskiner & Kontorsutrustning",
  "Uthyrning & Leasing Av Lastbilar & Andra Tunga Motorfordon",
  "Uthyrning & Leasing Av Personbilar & Lätta Motorfordon",
  "Uthyrning & Leasing Övrigt",
  "Begravningsverksamhet",
  "Konsumenttjänster, Övriga",
  "Kroppsvård",
  "Porträttfotoverksamhet",
  "Tvätteriverksamhet",
];

export const SelectOperationOptions = [
  {
      "text": "Advokatbyråer",
      "value": "Advokatbyråer"
  },
  {
      "text": "Alkoholhaltiga Drycker, Butikshandel",
      "value": "Alkoholhaltiga Drycker, Butikshandel"
  },
  {
      "text": "Ambassader & Internationella Org.",
      "value": "Ambassader & Internationella Org."
  },
  {
      "text": "Anläggningsarbeten",
      "value": "Anläggningsarbeten"
  },
  {
      "text": "Annonstidningsutgivning",
      "value": "Annonstidningsutgivning"
  },
  {
      "text": "Antikviteter & Beg. Böcker, Butikshandel",
      "value": "Antikviteter & Beg. Böcker, Butikshandel"
  },
  {
      "text": "Apotekshandel",
      "value": "Apotekshandel"
  },
  {
      "text": "Arbets- & Skyddskläder, Tillverkning",
      "value": "Arbets- & Skyddskläder, Tillverkning"
  },
  {
      "text": "Arbetsförmedling & Rekrytering",
      "value": "Arbetsförmedling & Rekrytering"
  },
  {
      "text": "Arbetsmarknadsprogram",
      "value": "Arbetsmarknadsprogram"
  },
  {
      "text": "Arbetsmarknadsutbildning",
      "value": "Arbetsmarknadsutbildning"
  },
  {
      "text": "Arkitektverksamhet",
      "value": "Arkitektverksamhet"
  },
  {
      "text": "Arkivverksamhet",
      "value": "Arkivverksamhet"
  },
  {
      "text": "Artistisk Verksamhet",
      "value": "Artistisk Verksamhet"
  },
  {
      "text": "Auktioner",
      "value": "Auktioner"
  },
  {
      "text": "Avfall & Skrot, Partihandel",
      "value": "Avfall & Skrot, Partihandel"
  },
  {
      "text": "Avfallshantering & Återvinning",
      "value": "Avfallshantering & Återvinning"
  },
  {
      "text": "Avloppsrening",
      "value": "Avloppsrening"
  },
  {
      "text": "Bageri- & Mjölprodukter",
      "value": "Bageri- & Mjölprodukter"
  },
  {
      "text": "Band & Skivor, Butikshandel",
      "value": "Band & Skivor, Butikshandel"
  },
  {
      "text": "Band & Skivor, Partihandel",
      "value": "Band & Skivor, Partihandel"
  },
  {
      "text": "Barnkläder, Butikshandel",
      "value": "Barnkläder, Butikshandel"
  },
  {
      "text": "Basplast, Tillverkning",
      "value": "Basplast, Tillverkning"
  },
  {
      "text": "Batteri- Och Ackumulatortillverkning",
      "value": "Batteri- Och Ackumulatortillverkning"
  },
  {
      "text": "Begagnade Varor Övriga, Butikshandel",
      "value": "Begagnade Varor Övriga, Butikshandel"
  },
  {
      "text": "Begravningsverksamhet",
      "value": "Begravningsverksamhet"
  },
  {
      "text": "Bekämpningsmedel & Lantbrukskem, Tillverkning",
      "value": "Bekämpningsmedel & Lantbrukskem, Tillverkning"
  },
  {
      "text": "Belysningsarmaturtillverkning",
      "value": "Belysningsarmaturtillverkning"
  },
  {
      "text": "Belysningsartiklar, Butikshandel",
      "value": "Belysningsartiklar, Butikshandel"
  },
  {
      "text": "Betong-, Cement- & Gipsvaror, Övriga",
      "value": "Betong-, Cement- & Gipsvaror, Övriga"
  },
  {
      "text": "Betongvarutillverkning",
      "value": "Betongvarutillverkning"
  },
  {
      "text": "Bibliotek",
      "value": "Bibliotek"
  },
  {
      "text": "Bijouteritillverkning",
      "value": "Bijouteritillverkning"
  },
  {
      "text": "Bioteknisk Forskning & Utveckling",
      "value": "Bioteknisk Forskning & Utveckling"
  },
  {
      "text": "Blandat Jordbruk",
      "value": "Blandat Jordbruk"
  },
  {
      "text": "Blandat Sortiment",
      "value": "Blandat Sortiment"
  },
  {
      "text": "Blommor & Växter, Butikshandel",
      "value": "Blommor & Växter, Butikshandel"
  },
  {
      "text": "Blommor & Växter, Partihandel",
      "value": "Blommor & Växter, Partihandel"
  },
  {
      "text": "Bokutgivning",
      "value": "Bokutgivning"
  },
  {
      "text": "Borstbinderitillverkning",
      "value": "Borstbinderitillverkning"
  },
  {
      "text": "Brand- & Räddningsverksamhet",
      "value": "Brand- & Räddningsverksamhet"
  },
  {
      "text": "Bränsle, Mineraler & Industrikem. Partihandel",
      "value": "Bränsle, Mineraler & Industrikem. Partihandel"
  },
  {
      "text": "Bröd & Konditori, Butikshandel",
      "value": "Bröd & Konditori, Butikshandel"
  },
  {
      "text": "Bud- & Kurirverksamhet",
      "value": "Bud- & Kurirverksamhet"
  },
  {
      "text": "Butikshandel, Övrig",
      "value": "Butikshandel, Övrig"
  },
  {
      "text": "Byggmaterialtillverkning",
      "value": "Byggmaterialtillverkning"
  },
  {
      "text": "Byggnadsmetallvaror, Tillverkning",
      "value": "Byggnadsmetallvaror, Tillverkning"
  },
  {
      "text": "Byggnadssnickeriarbeten",
      "value": "Byggnadssnickeriarbeten"
  },
  {
      "text": "Byggplastvarutillverkning",
      "value": "Byggplastvarutillverkning"
  },
  {
      "text": "Byggverksamhet",
      "value": "Byggverksamhet"
  },
  {
      "text": "Bärgning",
      "value": "Bärgning"
  },
  {
      "text": "Båt & Fartyg, Tillverkning",
      "value": "Båt & Fartyg, Tillverkning"
  },
  {
      "text": "Båtar, Butikshandel",
      "value": "Båtar, Butikshandel"
  },
  {
      "text": "Böcker, Butikshandel",
      "value": "Böcker, Butikshandel"
  },
  {
      "text": "Callcenterverksamhet",
      "value": "Callcenterverksamhet"
  },
  {
      "text": "Campingplatsverksamhet",
      "value": "Campingplatsverksamhet"
  },
  {
      "text": "Catering",
      "value": "Catering"
  },
  {
      "text": "Cementtillverkning",
      "value": "Cementtillverkning"
  },
  {
      "text": "Centralbanksverksamhet",
      "value": "Centralbanksverksamhet"
  },
  {
      "text": "Centralkök För Sjukhus",
      "value": "Centralkök För Sjukhus"
  },
  {
      "text": "Centralkök För Skolor & Omsorg",
      "value": "Centralkök För Skolor & Omsorg"
  },
  {
      "text": "Choklad- & Konfektyrtillverkning",
      "value": "Choklad- & Konfektyrtillverkning"
  },
  {
      "text": "Civilt Försvar & Frivilligförsvar",
      "value": "Civilt Försvar & Frivilligförsvar"
  },
  {
      "text": "Cyklar & Invalidfordon, Tillverkning",
      "value": "Cyklar & Invalidfordon, Tillverkning"
  },
  {
      "text": "Cyklar, Butikshandel",
      "value": "Cyklar, Butikshandel"
  },
  {
      "text": "Dagstidningsutgivning",
      "value": "Dagstidningsutgivning"
  },
  {
      "text": "Damkläder, Butikshandel",
      "value": "Damkläder, Butikshandel"
  },
  {
      "text": "Databehandling & Hosting",
      "value": "Databehandling & Hosting"
  },
  {
      "text": "Datakonsultverksamhet",
      "value": "Datakonsultverksamhet"
  },
  {
      "text": "Dataprogrammering",
      "value": "Dataprogrammering"
  },
  {
      "text": "Datordrifttjänster",
      "value": "Datordrifttjänster"
  },
  {
      "text": "Datorer & Kringutrustning, Reparation",
      "value": "Datorer & Kringutrustning, Reparation"
  },
  {
      "text": "Datorer & Kringutrustning, Tillverkning",
      "value": "Datorer & Kringutrustning, Tillverkning"
  },
  {
      "text": "Datorer, Program & Kringutr, Partihandel",
      "value": "Datorer, Program & Kringutr, Partihandel"
  },
  {
      "text": "Datorer, Program, Data-& Tv-Spel, Butikshandel",
      "value": "Datorer, Program, Data-& Tv-Spel, Butikshandel"
  },
  {
      "text": "Datoriserad Materialhanteringsutr, Partihandel",
      "value": "Datoriserad Materialhanteringsutr, Partihandel"
  },
  {
      "text": "Detaljhandel, Övrig",
      "value": "Detaljhandel, Övrig"
  },
  {
      "text": "Direktreklamverksamhet",
      "value": "Direktreklamverksamhet"
  },
  {
      "text": "Djurfoderframställning",
      "value": "Djurfoderframställning"
  },
  {
      "text": "Djuruppfödning, Övrig",
      "value": "Djuruppfödning, Övrig"
  },
  {
      "text": "Domstolsverksamhet",
      "value": "Domstolsverksamhet"
  },
  {
      "text": "Drivmedel, Detaljhandel",
      "value": "Drivmedel, Detaljhandel"
  },
  {
      "text": "Drivning",
      "value": "Drivning"
  },
  {
      "text": "Drycker, Partihandel",
      "value": "Drycker, Partihandel"
  },
  {
      "text": "Dryckesframställning",
      "value": "Dryckesframställning"
  },
  {
      "text": "Dörrar Av Trä, Tillverkning",
      "value": "Dörrar Av Trä, Tillverkning"
  },
  {
      "text": "Eftergymnasial Utbildning",
      "value": "Eftergymnasial Utbildning"
  },
  {
      "text": "El-VVS & Bygginstallationer",
      "value": "El-VVS & Bygginstallationer"
  },
  {
      "text": "Elapparatur Övrig, Tillverkning",
      "value": "Elapparatur Övrig, Tillverkning"
  },
  {
      "text": "Elapparatur, Reparation",
      "value": "Elapparatur, Reparation"
  },
  {
      "text": "Elartiklar, Partihandel",
      "value": "Elartiklar, Partihandel"
  },
  {
      "text": "Eldfasta Produkter",
      "value": "Eldfasta Produkter"
  },
  {
      "text": "Eldistribution",
      "value": "Eldistribution"
  },
  {
      "text": "Elektriska Hushållsmaskiner, Butikshandel",
      "value": "Elektriska Hushållsmaskiner, Butikshandel"
  },
  {
      "text": "Elektriska Hushållsmaskiner, Tillverkning",
      "value": "Elektriska Hushållsmaskiner, Tillverkning"
  },
  {
      "text": "Elektriska Komponenter & Kretskort, Tillverkning",
      "value": "Elektriska Komponenter & Kretskort, Tillverkning"
  },
  {
      "text": "Elektronikindustri",
      "value": "Elektronikindustri"
  },
  {
      "text": "Elektronikkomponenter, Partihandel",
      "value": "Elektronikkomponenter, Partihandel"
  },
  {
      "text": "Elektronisk & Optisk Utrustning, Reparation",
      "value": "Elektronisk & Optisk Utrustning, Reparation"
  },
  {
      "text": "Elgenerering",
      "value": "Elgenerering"
  },
  {
      "text": "Elhandel",
      "value": "Elhandel"
  },
  {
      "text": "Elöverföring",
      "value": "Elöverföring"
  },
  {
      "text": "Emballage, Partihandel",
      "value": "Emballage, Partihandel"
  },
  {
      "text": "Eteriska Oljor, Tillverkning",
      "value": "Eteriska Oljor, Tillverkning"
  },
  {
      "text": "Fabriksblandad Betongtillverkning",
      "value": "Fabriksblandad Betongtillverkning"
  },
  {
      "text": "Farligt Avfall",
      "value": "Farligt Avfall"
  },
  {
      "text": "Farmaceutiska Basprodukter, Tillverkning",
      "value": "Farmaceutiska Basprodukter, Tillverkning"
  },
  {
      "text": "Fartyg & Båtar, Reparation",
      "value": "Fartyg & Båtar, Reparation"
  },
  {
      "text": "Fastighetsförmedling",
      "value": "Fastighetsförmedling"
  },
  {
      "text": "Fastighetsförvaltning På Uppdrag",
      "value": "Fastighetsförvaltning På Uppdrag"
  },
  {
      "text": "Fastighetsrelaterade Stödtjänster",
      "value": "Fastighetsrelaterade Stödtjänster"
  },
  {
      "text": "Fibercementvarutillverkning",
      "value": "Fibercementvarutillverkning"
  },
  {
      "text": "Fiberväxtodling",
      "value": "Fiberväxtodling"
  },
  {
      "text": "Film, Video & Tv",
      "value": "Film, Video & Tv"
  },
  {
      "text": "Filmvisning",
      "value": "Filmvisning"
  },
  {
      "text": "Finans, Administrativa Tjänster",
      "value": "Finans, Administrativa Tjänster"
  },
  {
      "text": "Finansförmedling, Övrig",
      "value": "Finansförmedling, Övrig"
  },
  {
      "text": "Finansiell Leasing",
      "value": "Finansiell Leasing"
  },
  {
      "text": "Finansiella Stödtjänster, Övriga",
      "value": "Finansiella Stödtjänster, Övriga"
  },
  {
      "text": "Fisk & Skaldjur, Butikshandel",
      "value": "Fisk & Skaldjur, Butikshandel"
  },
  {
      "text": "Fisk, Skalddjur & Andra Livsmedel, Partihandel",
      "value": "Fisk, Skalddjur & Andra Livsmedel, Partihandel"
  },
  {
      "text": "Fisk, Skaldjur & Blötdjur, Beredning",
      "value": "Fisk, Skaldjur & Blötdjur, Beredning"
  },
  {
      "text": "Fiskodling",
      "value": "Fiskodling"
  },
  {
      "text": "Fjäderfä, Uppfödning",
      "value": "Fjäderfä, Uppfödning"
  },
  {
      "text": "Flyttjänster",
      "value": "Flyttjänster"
  },
  {
      "text": "Folkhögskoleutbildning",
      "value": "Folkhögskoleutbildning"
  },
  {
      "text": "Fondanknuten Livförsäkring",
      "value": "Fondanknuten Livförsäkring"
  },
  {
      "text": "Fonder & Liknande Finansiella Enheter, Övriga",
      "value": "Fonder & Liknande Finansiella Enheter, Övriga"
  },
  {
      "text": "Fondförvaltning, Övrig",
      "value": "Fondförvaltning, Övrig"
  },
  {
      "text": "Fotografiska & Optiska Produkter, Partihandel",
      "value": "Fotografiska & Optiska Produkter, Partihandel"
  },
  {
      "text": "Fotolaboratorieverksamhet",
      "value": "Fotolaboratorieverksamhet"
  },
  {
      "text": "Fotoutrustning, Butikshandel",
      "value": "Fotoutrustning, Butikshandel"
  },
  {
      "text": "Frisörer",
      "value": "Frisörer"
  },
  {
      "text": "Fritids-& Nöjesverksamhet, Övrig",
      "value": "Fritids-& Nöjesverksamhet, Övrig"
  },
  {
      "text": "Frukt & Grönsaker, Butikshandel",
      "value": "Frukt & Grönsaker, Butikshandel"
  },
  {
      "text": "Frukt & Grönsaker, Partihandel",
      "value": "Frukt & Grönsaker, Partihandel"
  },
  {
      "text": "Frukt, Bär & Nötter, Odling",
      "value": "Frukt, Bär & Nötter, Odling"
  },
  {
      "text": "Fruktodling",
      "value": "Fruktodling"
  },
  {
      "text": "Färg & Lack, Butikshandel",
      "value": "Färg & Lack, Butikshandel"
  },
  {
      "text": "Färg, Lack & Tryckfärg, Tillverkning",
      "value": "Färg, Lack & Tryckfärg, Tillverkning"
  },
  {
      "text": "Färgämnen, Tillverkning",
      "value": "Färgämnen, Tillverkning"
  },
  {
      "text": "Får- & Getuppfödning",
      "value": "Får- & Getuppfödning"
  },
  {
      "text": "Fönster Av Trä, Tillverkning",
      "value": "Fönster Av Trä, Tillverkning"
  },
  {
      "text": "Företagstjänster, Övriga",
      "value": "Företagstjänster, Övriga"
  },
  {
      "text": "Förlagsverksamhet, Övrig",
      "value": "Förlagsverksamhet, Övrig"
  },
  {
      "text": "Förpackningsverksamhet",
      "value": "Förpackningsverksamhet"
  },
  {
      "text": "Förskoleutbildning",
      "value": "Förskoleutbildning"
  },
  {
      "text": "Försäkring & Pensionsfond Stödtjänster, Övriga",
      "value": "Försäkring & Pensionsfond Stödtjänster, Övriga"
  },
  {
      "text": "Förvaltning & Handel Med Värdepapper",
      "value": "Förvaltning & Handel Med Värdepapper"
  },
  {
      "text": "Förvaltning Av Investeringsfonder",
      "value": "Förvaltning Av Investeringsfonder"
  },
  {
      "text": "Förvaltning I Bostadsrättsföreningar",
      "value": "Förvaltning I Bostadsrättsföreningar"
  },
  {
      "text": "Förvärvsarbete I Hushåll",
      "value": "Förvärvsarbete I Hushåll"
  },
  {
      "text": "Garntillverkning",
      "value": "Garntillverkning"
  },
  {
      "text": "Gasdistribution",
      "value": "Gasdistribution"
  },
  {
      "text": "Gasframställning",
      "value": "Gasframställning"
  },
  {
      "text": "Gashandel",
      "value": "Gashandel"
  },
  {
      "text": "Gipsvarutillverkning",
      "value": "Gipsvarutillverkning"
  },
  {
      "text": "Gjutning",
      "value": "Gjutning"
  },
  {
      "text": "Glas & Porslin, Butikshandel",
      "value": "Glas & Porslin, Butikshandel"
  },
  {
      "text": "Glas, Porslin & Rengöringsmedel, Partihandel",
      "value": "Glas, Porslin & Rengöringsmedel, Partihandel"
  },
  {
      "text": "Glas- & Glasvarutillverkning",
      "value": "Glas- & Glasvarutillverkning"
  },
  {
      "text": "Glasfibertillverkning",
      "value": "Glasfibertillverkning"
  },
  {
      "text": "Glasmästeriarbeten",
      "value": "Glasmästeriarbeten"
  },
  {
      "text": "Glasstillverkning",
      "value": "Glasstillverkning"
  },
  {
      "text": "Godshantering, Övrig",
      "value": "Godshantering, Övrig"
  },
  {
      "text": "Golfbanor",
      "value": "Golfbanor"
  },
  {
      "text": "Golv- & Väggbeläggningsarbeten",
      "value": "Golv- & Väggbeläggningsarbeten"
  },
  {
      "text": "Grafisk Designverksamhet",
      "value": "Grafisk Designverksamhet"
  },
  {
      "text": "Grafisk Produktion",
      "value": "Grafisk Produktion"
  },
  {
      "text": "Grundskole- & Gymnasieskoleutbildning",
      "value": "Grundskole- & Gymnasieskoleutbildning"
  },
  {
      "text": "Grundskoleutbildning",
      "value": "Grundskoleutbildning"
  },
  {
      "text": "Gruv-, Bygg- & Anläggningsmaskiner, Partihandel",
      "value": "Gruv-, Bygg- & Anläggningsmaskiner, Partihandel"
  },
  {
      "text": "Grönsaker, Frilandsodling",
      "value": "Grönsaker, Frilandsodling"
  },
  {
      "text": "Grönsaker, Växthusodling",
      "value": "Grönsaker, Växthusodling"
  },
  {
      "text": "Guldsmedsvaror & Smycken, Butikshandel",
      "value": "Guldsmedsvaror & Smycken, Butikshandel"
  },
  {
      "text": "Gummivaror, Tillverkning",
      "value": "Gummivaror, Tillverkning"
  },
  {
      "text": "Gymanläggningar",
      "value": "Gymanläggningar"
  },
  {
      "text": "Gymnasial Utbildning",
      "value": "Gymnasial Utbildning"
  },
  {
      "text": "Gödsel- & Kväveprodukter, Tillverkning",
      "value": "Gödsel- & Kväveprodukter, Tillverkning"
  },
  {
      "text": "Hamngodshantering",
      "value": "Hamngodshantering"
  },
  {
      "text": "Handel Med Egna Fastigheter",
      "value": "Handel Med Egna Fastigheter"
  },
  {
      "text": "Havs- & Sjöfart, Godstrafik",
      "value": "Havs- & Sjöfart, Godstrafik"
  },
  {
      "text": "Havs- & Sjöfart, Passagerartrafik",
      "value": "Havs- & Sjöfart, Passagerartrafik"
  },
  {
      "text": "Hemelektronik, Reparation",
      "value": "Hemelektronik, Reparation"
  },
  {
      "text": "Hemelektronik, Tillverkning",
      "value": "Hemelektronik, Tillverkning"
  },
  {
      "text": "Herrkläder, Butikshandel",
      "value": "Herrkläder, Butikshandel"
  },
  {
      "text": "Holdingverksamhet I Finansiella Koncerner",
      "value": "Holdingverksamhet I Finansiella Koncerner"
  },
  {
      "text": "Holdingverksamhet I Icke-Finansiella Koncerner",
      "value": "Holdingverksamhet I Icke-Finansiella Koncerner"
  },
  {
      "text": "Hotell & Restaurang",
      "value": "Hotell & Restaurang"
  },
  {
      "text": "Hudar, Skinn & Läder, Partihandel",
      "value": "Hudar, Skinn & Läder, Partihandel"
  },
  {
      "text": "Hushålls- & Personartiklar, Reparation, Övriga",
      "value": "Hushålls- & Personartiklar, Reparation, Övriga"
  },
  {
      "text": "Hushållsapparater & Elartiklar, Partihandel",
      "value": "Hushållsapparater & Elartiklar, Partihandel"
  },
  {
      "text": "Hushållsapparater, Hem & Trädgård, Reparation",
      "value": "Hushållsapparater, Hem & Trädgård, Reparation"
  },
  {
      "text": "Hushållsvaror Övriga, Partihandel",
      "value": "Hushållsvaror Övriga, Partihandel"
  },
  {
      "text": "Husvagnar, Husbilar & Släp, Handel",
      "value": "Husvagnar, Husbilar & Släp, Handel"
  },
  {
      "text": "Hälso- & Sjukvård, Administration",
      "value": "Hälso- & Sjukvård, Administration"
  },
  {
      "text": "Hälso- & Sjukvård, Övriga",
      "value": "Hälso- & Sjukvård, Övriga"
  },
  {
      "text": "Hälsokost, Butikshandel",
      "value": "Hälsokost, Butikshandel"
  },
  {
      "text": "Hästuppfödning",
      "value": "Hästuppfödning"
  },
  {
      "text": "Icke Spec. Handel Med Livsmedel, Partihandel",
      "value": "Icke Spec. Handel Med Livsmedel, Partihandel"
  },
  {
      "text": "Icke-Farligt Avfall",
      "value": "Icke-Farligt Avfall"
  },
  {
      "text": "Idrottsplatser & Sportanläggningar",
      "value": "Idrottsplatser & Sportanläggningar"
  },
  {
      "text": "Industri- & Produktdesignverksamhet",
      "value": "Industri- & Produktdesignverksamhet"
  },
  {
      "text": "Industri- Maskiner & Utrustning, Installation",
      "value": "Industri- Maskiner & Utrustning, Installation"
  },
  {
      "text": "Industriförnödenheter, Partihandel",
      "value": "Industriförnödenheter, Partihandel"
  },
  {
      "text": "Industrigasframställning",
      "value": "Industrigasframställning"
  },
  {
      "text": "Informationstjänster, Övriga",
      "value": "Informationstjänster, Övriga"
  },
  {
      "text": "Infrastrukturprogram",
      "value": "Infrastrukturprogram"
  },
  {
      "text": "Inkasso- & Kreditupplysningsverksamhet",
      "value": "Inkasso- & Kreditupplysningsverksamhet"
  },
  {
      "text": "Inredningsarkitekt",
      "value": "Inredningsarkitekt"
  },
  {
      "text": "Inredningstextilier, Butikshandel",
      "value": "Inredningstextilier, Butikshandel"
  },
  {
      "text": "Insatsvaror Övriga, Partihandel",
      "value": "Insatsvaror Övriga, Partihandel"
  },
  {
      "text": "Inspektion, Kontroll & Tillståndsgivning",
      "value": "Inspektion, Kontroll & Tillståndsgivning"
  },
  {
      "text": "Intressebev. Arbetsgivarorg.",
      "value": "Intressebev. Arbetsgivarorg."
  },
  {
      "text": "Intressebev. Arbetstagarorg.",
      "value": "Intressebev. Arbetstagarorg."
  },
  {
      "text": "Intressebev. Branschorg.",
      "value": "Intressebev. Branschorg."
  },
  {
      "text": "Intressebev. Yrkesorg.",
      "value": "Intressebev. Yrkesorg."
  },
  {
      "text": "Intressorganisationer, övriga",
      "value": "Intressorganisationer, övriga"
  },
  {
      "text": "Investeringsfonder",
      "value": "Investeringsfonder"
  },
  {
      "text": "Investment- & Riskkapitalbolag",
      "value": "Investment- & Riskkapitalbolag"
  },
  {
      "text": "It- & Datatjänster, Övriga",
      "value": "It- & Datatjänster, Övriga"
  },
  {
      "text": "Jakt",
      "value": "Jakt"
  },
  {
      "text": "Jord- & Skogsbruk, Administration Av Program",
      "value": "Jord- & Skogsbruk, Administration Av Program"
  },
  {
      "text": "Jord- & Skogsbruksmaskiner, Tillverkning",
      "value": "Jord- & Skogsbruksmaskiner, Tillverkning"
  },
  {
      "text": "Jordbruks- & Textilråvaror, Provisionshandel",
      "value": "Jordbruks- & Textilråvaror, Provisionshandel"
  },
  {
      "text": "Jordbruksmaskiner, Partihandel",
      "value": "Jordbruksmaskiner, Partihandel"
  },
  {
      "text": "Juice & Safttillverkning",
      "value": "Juice & Safttillverkning"
  },
  {
      "text": "Juridik, Ekonomi, Vetenskap & Teknik, Övrig",
      "value": "Juridik, Ekonomi, Vetenskap & Teknik, Övrig"
  },
  {
      "text": "Juridisk Verksamhet, Övrig",
      "value": "Juridisk Verksamhet, Övrig"
  },
  {
      "text": "Järn- & Vvs- Varor, Butikshandel",
      "value": "Järn- & Vvs- Varor, Butikshandel"
  },
  {
      "text": "Järnhandelsvaror, Partihandel",
      "value": "Järnhandelsvaror, Partihandel"
  },
  {
      "text": "Järnmalmsutvinning",
      "value": "Järnmalmsutvinning"
  },
  {
      "text": "Järnvägstransport- Passagerartrafik",
      "value": "Järnvägstransport- Passagerartrafik"
  },
  {
      "text": "Järnvägstransport-Godstrafik",
      "value": "Järnvägstransport-Godstrafik"
  },
  {
      "text": "Kabeltillbehör, Tillverkning",
      "value": "Kabeltillbehör, Tillverkning"
  },
  {
      "text": "Kaffe, Te, Kakao & Kryddor, Partihandel",
      "value": "Kaffe, Te, Kakao & Kryddor, Partihandel"
  },
  {
      "text": "Kalk & Gipstillverkning",
      "value": "Kalk & Gipstillverkning"
  },
  {
      "text": "Kamel- & Kameldjursuppfödning",
      "value": "Kamel- & Kameldjursuppfödning"
  },
  {
      "text": "Kemiska Produkter, Partihandel",
      "value": "Kemiska Produkter, Partihandel"
  },
  {
      "text": "Kemiska Produkter, Tillverkning",
      "value": "Kemiska Produkter, Tillverkning"
  },
  {
      "text": "Keramiska Golv- & Väggplattor",
      "value": "Keramiska Golv- & Väggplattor"
  },
  {
      "text": "Keramiska Produkter, Tillverkning",
      "value": "Keramiska Produkter, Tillverkning"
  },
  {
      "text": "Klockor, Butikshandel",
      "value": "Klockor, Butikshandel"
  },
  {
      "text": "Kläder & Skor, Partihandel",
      "value": "Kläder & Skor, Partihandel"
  },
  {
      "text": "Kläder & Textilier, Tillverkning",
      "value": "Kläder & Textilier, Tillverkning"
  },
  {
      "text": "Kläder, Butikshandel",
      "value": "Kläder, Butikshandel"
  },
  {
      "text": "Kollektivtrafik, Övrig",
      "value": "Kollektivtrafik, Övrig"
  },
  {
      "text": "Kommunikationsutrustning, Reparation",
      "value": "Kommunikationsutrustning, Reparation"
  },
  {
      "text": "Kommunikationsutrustning, Tillverkning",
      "value": "Kommunikationsutrustning, Tillverkning"
  },
  {
      "text": "Konfektyr, Butikshandel",
      "value": "Konfektyr, Butikshandel"
  },
  {
      "text": "Konferensanläggningar",
      "value": "Konferensanläggningar"
  },
  {
      "text": "Kongresser & Mässor",
      "value": "Kongresser & Mässor"
  },
  {
      "text": "Konst & Galleri, Butikshandel",
      "value": "Konst & Galleri, Butikshandel"
  },
  {
      "text": "Konstfiber, Tillverkning",
      "value": "Konstfiber, Tillverkning"
  },
  {
      "text": "Konsultverksamhet Avseende Företags Org.",
      "value": "Konsultverksamhet Avseende Företags Org."
  },
  {
      "text": "Konsumenttjänster, Övriga",
      "value": "Konsumenttjänster, Övriga"
  },
  {
      "text": "Kontors- & Butiksinred, Tillverkning",
      "value": "Kontors- & Butiksinred, Tillverkning"
  },
  {
      "text": "Kontors- & Butiksmöber, Tillverkning",
      "value": "Kontors- & Butiksmöber, Tillverkning"
  },
  {
      "text": "Kontorsförbrukningsvaror, Butikshandel",
      "value": "Kontorsförbrukningsvaror, Butikshandel"
  },
  {
      "text": "Kontorsförbrukningsvaror, Partihandel",
      "value": "Kontorsförbrukningsvaror, Partihandel"
  },
  {
      "text": "Kontorsmaskiner & Kontorsutrustning, Partihandel",
      "value": "Kontorsmaskiner & Kontorsutrustning, Partihandel"
  },
  {
      "text": "Kontorsmaskiner, Tillverkning",
      "value": "Kontorsmaskiner, Tillverkning"
  },
  {
      "text": "Kontorsmöbler, Butikshandel",
      "value": "Kontorsmöbler, Butikshandel"
  },
  {
      "text": "Kontorsmöbler, Partihandel",
      "value": "Kontorsmöbler, Partihandel"
  },
  {
      "text": "Kontorstjänster",
      "value": "Kontorstjänster"
  },
  {
      "text": "Kontorsutrustning & Datorer, Partihandel",
      "value": "Kontorsutrustning & Datorer, Partihandel"
  },
  {
      "text": "Kosmetika & Hygienartiklar, Butikshandel",
      "value": "Kosmetika & Hygienartiklar, Butikshandel"
  },
  {
      "text": "Kreditgivning, Övrig",
      "value": "Kreditgivning, Övrig"
  },
  {
      "text": "Kriminalvård",
      "value": "Kriminalvård"
  },
  {
      "text": "Kroppsvård",
      "value": "Kroppsvård"
  },
  {
      "text": "Kultur, Miljö, Boende, Administration",
      "value": "Kultur, Miljö, Boende, Administration"
  },
  {
      "text": "Kultur, Nöje & Fritid",
      "value": "Kultur, Nöje & Fritid"
  },
  {
      "text": "Kvarnprodukter",
      "value": "Kvarnprodukter"
  },
  {
      "text": "Källsorterat Material",
      "value": "Källsorterat Material"
  },
  {
      "text": "Köksinredningar, Tillverkning",
      "value": "Köksinredningar, Tillverkning"
  },
  {
      "text": "Köksmöbler, Tillverkning",
      "value": "Köksmöbler, Tillverkning"
  },
  {
      "text": "Kött & Charkuterier, Butikshandel",
      "value": "Kött & Charkuterier, Butikshandel"
  },
  {
      "text": "Kött & Köttvaror, Partihandel",
      "value": "Kött & Köttvaror, Partihandel"
  },
  {
      "text": "Köttprodukter",
      "value": "Köttprodukter"
  },
  {
      "text": "Landtransport Av Passagerare, Övrig",
      "value": "Landtransport Av Passagerare, Övrig"
  },
  {
      "text": "Landtransport, Stödtjänster",
      "value": "Landtransport, Stödtjänster"
  },
  {
      "text": "Lastbilar, Bussar & Specialfordon, Handel",
      "value": "Lastbilar, Bussar & Specialfordon, Handel"
  },
  {
      "text": "Leasing Av Immateriell Egendom & Liknande Prod.",
      "value": "Leasing Av Immateriell Egendom & Liknande Prod."
  },
  {
      "text": "Levande Djur, Partihandel",
      "value": "Levande Djur, Partihandel"
  },
  {
      "text": "Lim, Tillverkning",
      "value": "Lim, Tillverkning"
  },
  {
      "text": "Linjebussverksamhet",
      "value": "Linjebussverksamhet"
  },
  {
      "text": "Litterärt & Konstnärligt Skapande",
      "value": "Litterärt & Konstnärligt Skapande"
  },
  {
      "text": "Livförsäkring, Övrig",
      "value": "Livförsäkring, Övrig"
  },
  {
      "text": "Livsmedel Övriga, Butikshandel",
      "value": "Livsmedel Övriga, Butikshandel"
  },
  {
      "text": "Livsmedel, Dryck & Tobak, Partihandel",
      "value": "Livsmedel, Dryck & Tobak, Partihandel"
  },
  {
      "text": "Livsmedelsframställning",
      "value": "Livsmedelsframställning"
  },
  {
      "text": "Livsmedelshandel",
      "value": "Livsmedelshandel"
  },
  {
      "text": "Ljud & Bild, Butikshandel",
      "value": "Ljud & Bild, Butikshandel"
  },
  {
      "text": "Ljud, Bild & Videoutrustning, Partihandel",
      "value": "Ljud, Bild & Videoutrustning, Partihandel"
  },
  {
      "text": "Ljudinspelning & Fonogramutgivning",
      "value": "Ljudinspelning & Fonogramutgivning"
  },
  {
      "text": "Logiverksamhet, Övrig",
      "value": "Logiverksamhet, Övrig"
  },
  {
      "text": "Luftfartyg & Rymdfarkoster, Reparation",
      "value": "Luftfartyg & Rymdfarkoster, Reparation"
  },
  {
      "text": "Lufttransport, Godstrafik",
      "value": "Lufttransport, Godstrafik"
  },
  {
      "text": "Lufttransport, Passagerartrafik",
      "value": "Lufttransport, Passagerartrafik"
  },
  {
      "text": "Lufttransporter, Stödtjänster",
      "value": "Lufttransporter, Stödtjänster"
  },
  {
      "text": "Läder- & Skinnkläder, Tillverkning",
      "value": "Läder- & Skinnkläder, Tillverkning"
  },
  {
      "text": "Läkemedel, Tillverkning",
      "value": "Läkemedel, Tillverkning"
  },
  {
      "text": "Madrasser, Tillverkning",
      "value": "Madrasser, Tillverkning"
  },
  {
      "text": "Magasinering & Varulagring",
      "value": "Magasinering & Varulagring"
  },
  {
      "text": "Malmutvinning, Övrig",
      "value": "Malmutvinning, Övrig"
  },
  {
      "text": "Marknads- & Opinionsundersökning",
      "value": "Marknads- & Opinionsundersökning"
  },
  {
      "text": "Markundersökning",
      "value": "Markundersökning"
  },
  {
      "text": "Maskiner & Utrustning Övriga, Partihandel",
      "value": "Maskiner & Utrustning Övriga, Partihandel"
  },
  {
      "text": "Maskiner, Industriell Utrustning, Partihandel",
      "value": "Maskiner, Industriell Utrustning, Partihandel"
  },
  {
      "text": "Maskiner, Reparation",
      "value": "Maskiner, Reparation"
  },
  {
      "text": "Maskiner, Tillverkning",
      "value": "Maskiner, Tillverkning"
  },
  {
      "text": "Mattor & Väggbeklädnad, Butikshandel",
      "value": "Mattor & Väggbeklädnad, Butikshandel"
  },
  {
      "text": "Mattor, Tillverkning",
      "value": "Mattor, Tillverkning"
  },
  {
      "text": "Medicin- & Dentalinstrumenttillverkning",
      "value": "Medicin- & Dentalinstrumenttillverkning"
  },
  {
      "text": "Medicinsk Utrustning & Apoteksvaror, Partihandel",
      "value": "Medicinsk Utrustning & Apoteksvaror, Partihandel"
  },
  {
      "text": "Mejeriprodukter, Ägg, Matolja & Matfett, Partihandel",
      "value": "Mejeriprodukter, Ägg, Matolja & Matfett, Partihandel"
  },
  {
      "text": "Mejerivarutillverkning",
      "value": "Mejerivarutillverkning"
  },
  {
      "text": "Metallavfall & Metallskrot, Partihandel",
      "value": "Metallavfall & Metallskrot, Partihandel"
  },
  {
      "text": "Metaller & Metallmalmer, Partihandel",
      "value": "Metaller & Metallmalmer, Partihandel"
  },
  {
      "text": "Metallindustri",
      "value": "Metallindustri"
  },
  {
      "text": "Metallvaror, Reparation",
      "value": "Metallvaror, Reparation"
  },
  {
      "text": "Militära Stridsfordon, Tillverkning",
      "value": "Militära Stridsfordon, Tillverkning"
  },
  {
      "text": "Militärt Försvar",
      "value": "Militärt Försvar"
  },
  {
      "text": "Mineralutvinning, Övrig",
      "value": "Mineralutvinning, Övrig"
  },
  {
      "text": "Mjölkproduktion & Nötkreatursuppfödning",
      "value": "Mjölkproduktion & Nötkreatursuppfödning"
  },
  {
      "text": "Monetär Finansförmedling, Övrig",
      "value": "Monetär Finansförmedling, Övrig"
  },
  {
      "text": "Motorbanor",
      "value": "Motorbanor"
  },
  {
      "text": "Motorcyklar, Handel, Service & Tillbehör",
      "value": "Motorcyklar, Handel, Service & Tillbehör"
  },
  {
      "text": "Motorcyklar, Tillverkning",
      "value": "Motorcyklar, Tillverkning"
  },
  {
      "text": "Motorer & Turbiner, Tillverkning",
      "value": "Motorer & Turbiner, Tillverkning"
  },
  {
      "text": "Motorfordon, Reparation & Underhåll",
      "value": "Motorfordon, Reparation & Underhåll"
  },
  {
      "text": "Motorfordon, Reservdelar & Tillbehör",
      "value": "Motorfordon, Reservdelar & Tillbehör"
  },
  {
      "text": "Motorfordonstillverkning",
      "value": "Motorfordonstillverkning"
  },
  {
      "text": "Murbrukstillverkning",
      "value": "Murbrukstillverkning"
  },
  {
      "text": "Museiverksamhet",
      "value": "Museiverksamhet"
  },
  {
      "text": "Musik-, Dans- & Kulturell Utbildning",
      "value": "Musik-, Dans- & Kulturell Utbildning"
  },
  {
      "text": "Musikinstrument & Noter, Butikshandel",
      "value": "Musikinstrument & Noter, Butikshandel"
  },
  {
      "text": "Musikinstrumenttillverkning",
      "value": "Musikinstrumenttillverkning"
  },
  {
      "text": "Mynt & Frimärken, Butikshandel",
      "value": "Mynt & Frimärken, Butikshandel"
  },
  {
      "text": "Mynttillverkning",
      "value": "Mynttillverkning"
  },
  {
      "text": "Mät- & Precisionsinstrument, Partihandel",
      "value": "Mät- & Precisionsinstrument, Partihandel"
  },
  {
      "text": "Måleriarbeten",
      "value": "Måleriarbeten"
  },
  {
      "text": "Möbler & Heminredning, Reparation",
      "value": "Möbler & Heminredning, Reparation"
  },
  {
      "text": "Möbler För Hemmet, Butikshandel",
      "value": "Möbler För Hemmet, Butikshandel"
  },
  {
      "text": "Möbler Övriga, Tillverkning",
      "value": "Möbler Övriga, Tillverkning"
  },
  {
      "text": "Möbler, Hushålls- & Järnhandelsvaror, Partihandel",
      "value": "Möbler, Hushålls- & Järnhandelsvaror, Partihandel"
  },
  {
      "text": "Möbler, Mattor & Belysning, Partihandel",
      "value": "Möbler, Mattor & Belysning, Partihandel"
  },
  {
      "text": "Naturvetenskaplig Och Teknisk F&U",
      "value": "Naturvetenskaplig Och Teknisk F&U"
  },
  {
      "text": "Nyhetsservice",
      "value": "Nyhetsservice"
  },
  {
      "text": "Näringslivsprogram, Övriga",
      "value": "Näringslivsprogram, Övriga"
  },
  {
      "text": "Nöjes- & Temaparksverksamhet",
      "value": "Nöjes- & Temaparksverksamhet"
  },
  {
      "text": "Nötkreatur & Bufflar, Övriga",
      "value": "Nötkreatur & Bufflar, Övriga"
  },
  {
      "text": "Obligatorisk Socialförsäkring",
      "value": "Obligatorisk Socialförsäkring"
  },
  {
      "text": "Omsorg & Socialtjänst",
      "value": "Omsorg & Socialtjänst"
  },
  {
      "text": "Oorganiska Baskemikalier, Tillverkning",
      "value": "Oorganiska Baskemikalier, Tillverkning"
  },
  {
      "text": "Optiker, Butikshandel",
      "value": "Optiker, Butikshandel"
  },
  {
      "text": "Optiska Fiberkabeltillverkning",
      "value": "Optiska Fiberkabeltillverkning"
  },
  {
      "text": "Optiska Instrument & Fotoutrustning",
      "value": "Optiska Instrument & Fotoutrustning"
  },
  {
      "text": "Organiska Baskemikalier, Tillverkning",
      "value": "Organiska Baskemikalier, Tillverkning"
  },
  {
      "text": "Pappers- & Pappersvarutillverkning",
      "value": "Pappers- & Pappersvarutillverkning"
  },
  {
      "text": "Parfym & Kosmetika, Partihandel",
      "value": "Parfym & Kosmetika, Partihandel"
  },
  {
      "text": "Partihandel",
      "value": "Partihandel"
  },
  {
      "text": "Partihandel, Övrig",
      "value": "Partihandel, Övrig"
  },
  {
      "text": "Patentbyråer",
      "value": "Patentbyråer"
  },
  {
      "text": "Pensionsfondsverksamhet",
      "value": "Pensionsfondsverksamhet"
  },
  {
      "text": "Personalfunktioner, Övrigt",
      "value": "Personalfunktioner, Övrigt"
  },
  {
      "text": "Personalförvaltning & Andra Stödtjänster",
      "value": "Personalförvaltning & Andra Stödtjänster"
  },
  {
      "text": "Personalmatsalar",
      "value": "Personalmatsalar"
  },
  {
      "text": "Personalutbildning",
      "value": "Personalutbildning"
  },
  {
      "text": "Personaluthyrning",
      "value": "Personaluthyrning"
  },
  {
      "text": "Personbilar & Lätta Motorfordon, Handel",
      "value": "Personbilar & Lätta Motorfordon, Handel"
  },
  {
      "text": "Petroleumraffinering",
      "value": "Petroleumraffinering"
  },
  {
      "text": "Planglas",
      "value": "Planglas"
  },
  {
      "text": "Plantskoleväxter, Odling",
      "value": "Plantskoleväxter, Odling"
  },
  {
      "text": "Plastförpackningstillverkning",
      "value": "Plastförpackningstillverkning"
  },
  {
      "text": "Plastvaror, Tillverkning",
      "value": "Plastvaror, Tillverkning"
  },
  {
      "text": "Plastvarutillverkning, Övrig",
      "value": "Plastvarutillverkning, Övrig"
  },
  {
      "text": "Polisverksamhet",
      "value": "Polisverksamhet"
  },
  {
      "text": "Politiska organisationer",
      "value": "Politiska organisationer"
  },
  {
      "text": "Porträttfotoverksamhet",
      "value": "Porträttfotoverksamhet"
  },
  {
      "text": "Post- & Kurirverksamhet",
      "value": "Post- & Kurirverksamhet"
  },
  {
      "text": "Postorder- & Internethandel",
      "value": "Postorder- & Internethandel"
  },
  {
      "text": "Potatisberedning",
      "value": "Potatisberedning"
  },
  {
      "text": "Potatisodling",
      "value": "Potatisodling"
  },
  {
      "text": "Press- & Övrig Fotografverksamhet",
      "value": "Press- & Övrig Fotografverksamhet"
  },
  {
      "text": "Primärvårdsmottagning",
      "value": "Primärvårdsmottagning"
  },
  {
      "text": "Publicering Av Kataloger & Sändlistor",
      "value": "Publicering Av Kataloger & Sändlistor"
  },
  {
      "text": "Puts-, Fasad- & Stuckatörsarbeten",
      "value": "Puts-, Fasad- & Stuckatörsarbeten"
  },
  {
      "text": "Pälsar, Butikshandel",
      "value": "Pälsar, Butikshandel"
  },
  {
      "text": "Pälsvaror, Tillverkning",
      "value": "Pälsvaror, Tillverkning"
  },
  {
      "text": "Radiosändning",
      "value": "Radiosändning"
  },
  {
      "text": "Redovisning & Bokföring",
      "value": "Redovisning & Bokföring"
  },
  {
      "text": "Reklam, Pr, Mediebyrå & Annonsförsälj.",
      "value": "Reklam, Pr, Mediebyrå & Annonsförsälj."
  },
  {
      "text": "Reklamfotoverksamhet",
      "value": "Reklamfotoverksamhet"
  },
  {
      "text": "Religiösa samfund",
      "value": "Religiösa samfund"
  },
  {
      "text": "Rengöring & Lokalvård",
      "value": "Rengöring & Lokalvård"
  },
  {
      "text": "Renskötsel",
      "value": "Renskötsel"
  },
  {
      "text": "Reproduktion Av Inspelningar",
      "value": "Reproduktion Av Inspelningar"
  },
  {
      "text": "Researrangemang",
      "value": "Researrangemang"
  },
  {
      "text": "Resebyråer",
      "value": "Resebyråer"
  },
  {
      "text": "Restaurangverksamhet",
      "value": "Restaurangverksamhet"
  },
  {
      "text": "Revision",
      "value": "Revision"
  },
  {
      "text": "Risk- & Skadebedömning",
      "value": "Risk- & Skadebedömning"
  },
  {
      "text": "Rivning av hus & byggnader",
      "value": "Rivning av hus & byggnader"
  },
  {
      "text": "Rymdfart",
      "value": "Rymdfart"
  },
  {
      "text": "Rälsfordon, Tillverkning",
      "value": "Rälsfordon, Tillverkning"
  },
  {
      "text": "Råpetroleumutvinning",
      "value": "Råpetroleumutvinning"
  },
  {
      "text": "Rörsystem",
      "value": "Rörsystem"
  },
  {
      "text": "Saltvattensfiske",
      "value": "Saltvattensfiske"
  },
  {
      "text": "Samhällelig Informationsförsörjning",
      "value": "Samhällelig Informationsförsörjning"
  },
  {
      "text": "Samhällsvet. & Humanistisk F&U",
      "value": "Samhällsvet. & Humanistisk F&U"
  },
  {
      "text": "Sanitetsgods, Partihandel",
      "value": "Sanitetsgods, Partihandel"
  },
  {
      "text": "Service Till Husdjursskötsel",
      "value": "Service Till Husdjursskötsel"
  },
  {
      "text": "Service Till Skogsbruk",
      "value": "Service Till Skogsbruk"
  },
  {
      "text": "Service Till Växtodling",
      "value": "Service Till Växtodling"
  },
  {
      "text": "Sjukvårdsartiklar, Butikshandel",
      "value": "Sjukvårdsartiklar, Butikshandel"
  },
  {
      "text": "Sjötransport, Stödtjänster",
      "value": "Sjötransport, Stödtjänster"
  },
  {
      "text": "Skadeförsäkring",
      "value": "Skadeförsäkring"
  },
  {
      "text": "Skatteförvaltning & Indrivning",
      "value": "Skatteförvaltning & Indrivning"
  },
  {
      "text": "Skatterådgivning",
      "value": "Skatterådgivning"
  },
  {
      "text": "Skidsportanläggningar",
      "value": "Skidsportanläggningar"
  },
  {
      "text": "Skogsbruk",
      "value": "Skogsbruk"
  },
  {
      "text": "Skogsförvaltning",
      "value": "Skogsförvaltning"
  },
  {
      "text": "Skogsskötsel",
      "value": "Skogsskötsel"
  },
  {
      "text": "Skomakare",
      "value": "Skomakare"
  },
  {
      "text": "Skor, Butikshandel",
      "value": "Skor, Butikshandel"
  },
  {
      "text": "Skor, Tillverkning",
      "value": "Skor, Tillverkning"
  },
  {
      "text": "Skönhetsvård",
      "value": "Skönhetsvård"
  },
  {
      "text": "Skötsel & Underhåll Av Grönytor",
      "value": "Skötsel & Underhåll Av Grönytor"
  },
  {
      "text": "Slaktsvinuppfödning",
      "value": "Slaktsvinuppfödning"
  },
  {
      "text": "Slutbehandling av byggnader",
      "value": "Slutbehandling av byggnader"
  },
  {
      "text": "Sluten Sjukvård",
      "value": "Sluten Sjukvård"
  },
  {
      "text": "Smyckestillverkning",
      "value": "Smyckestillverkning"
  },
  {
      "text": "Smågrisuppfödning",
      "value": "Smågrisuppfödning"
  },
  {
      "text": "Sociala Insatser",
      "value": "Sociala Insatser"
  },
  {
      "text": "Socker, Choklad & Sockerkonfekt, Partihandel",
      "value": "Socker, Choklad & Sockerkonfekt, Partihandel"
  },
  {
      "text": "Sockertillverkning",
      "value": "Sockertillverkning"
  },
  {
      "text": "Socketbetsodling",
      "value": "Socketbetsodling"
  },
  {
      "text": "Spanings- & Detektivverksamhet",
      "value": "Spanings- & Detektivverksamhet"
  },
  {
      "text": "Spannmål, Balj- & Oljeväxter, Odling",
      "value": "Spannmål, Balj- & Oljeväxter, Odling"
  },
  {
      "text": "Specialistläkare Ej På Sjukhus",
      "value": "Specialistläkare Ej På Sjukhus"
  },
  {
      "text": "Specialistläkare På Sjukhus",
      "value": "Specialistläkare På Sjukhus"
  },
  {
      "text": "Specialsortiment",
      "value": "Specialsortiment"
  },
  {
      "text": "Spel & Leksaker, Butikshandel",
      "value": "Spel & Leksaker, Butikshandel"
  },
  {
      "text": "Spel- & Leksakstillverkning",
      "value": "Spel- & Leksakstillverkning"
  },
  {
      "text": "Spel- & Vadhållningsverksamhet",
      "value": "Spel- & Vadhållningsverksamhet"
  },
  {
      "text": "Sport- & Fritidsartiklar, Butikshandel",
      "value": "Sport- & Fritidsartiklar, Butikshandel"
  },
  {
      "text": "Sport- & Fritidsartiklar, Partihandel",
      "value": "Sport- & Fritidsartiklar, Partihandel"
  },
  {
      "text": "Sport- & Fritidsutbildning",
      "value": "Sport- & Fritidsutbildning"
  },
  {
      "text": "Sportartikelstillverkning",
      "value": "Sportartikelstillverkning"
  },
  {
      "text": "Sportklubbars & Idrottsför. Verksamhet",
      "value": "Sportklubbars & Idrottsför. Verksamhet"
  },
  {
      "text": "Sportverksamhet, Övrig",
      "value": "Sportverksamhet, Övrig"
  },
  {
      "text": "Sprängämne, Tillverkning",
      "value": "Sprängämne, Tillverkning"
  },
  {
      "text": "Stats- & Kommunledning, Lagstiftning & Planering",
      "value": "Stats- & Kommunledning, Lagstiftning & Planering"
  },
  {
      "text": "Stenhuggning",
      "value": "Stenhuggning"
  },
  {
      "text": "Strumpor, Tillverkning",
      "value": "Strumpor, Tillverkning"
  },
  {
      "text": "Studieförbundens & Frivilligorg. Utbildning",
      "value": "Studieförbundens & Frivilligorg. Utbildning"
  },
  {
      "text": "Stugbyverksamhet",
      "value": "Stugbyverksamhet"
  },
  {
      "text": "Stärkelsetillverkning",
      "value": "Stärkelsetillverkning"
  },
  {
      "text": "Svampodling",
      "value": "Svampodling"
  },
  {
      "text": "Syntetiskt Basgummi, Tillverkning",
      "value": "Syntetiskt Basgummi, Tillverkning"
  },
  {
      "text": "Säkerhetssystemtjänster",
      "value": "Säkerhetssystemtjänster"
  },
  {
      "text": "Säkerhetsverksamhet",
      "value": "Säkerhetsverksamhet"
  },
  {
      "text": "Sällskapsdjur, Butikshandel",
      "value": "Sällskapsdjur, Butikshandel"
  },
  {
      "text": "Sällskapsdjur, Uppfödning",
      "value": "Sällskapsdjur, Uppfödning"
  },
  {
      "text": "Sågning, Hyvling & Impregnering",
      "value": "Sågning, Hyvling & Impregnering"
  },
  {
      "text": "Sötvattensfiske",
      "value": "Sötvattensfiske"
  },
  {
      "text": "Takarbeten",
      "value": "Takarbeten"
  },
  {
      "text": "Tandläkare",
      "value": "Tandläkare"
  },
  {
      "text": "Tandprotestillverkning",
      "value": "Tandprotestillverkning"
  },
  {
      "text": "Taxi",
      "value": "Taxi"
  },
  {
      "text": "Te- & Kaffetillverkning",
      "value": "Te- & Kaffetillverkning"
  },
  {
      "text": "Teknisk Konsult Inom Elteknik",
      "value": "Teknisk Konsult Inom Elteknik"
  },
  {
      "text": "Teknisk Konsult Inom Energi-, Miljö- & Vvs-Teknik",
      "value": "Teknisk Konsult Inom Energi-, Miljö- & Vvs-Teknik"
  },
  {
      "text": "Teknisk Konsult Inom Industriteknik",
      "value": "Teknisk Konsult Inom Industriteknik"
  },
  {
      "text": "Teknisk Konsultverksamhet, Övrig",
      "value": "Teknisk Konsultverksamhet, Övrig"
  },
  {
      "text": "Teknisk Provning & Analys",
      "value": "Teknisk Provning & Analys"
  },
  {
      "text": "Teknisk konsult inom Bygg- & Anläggningsteknik",
      "value": "Teknisk konsult inom Bygg- & Anläggningsteknik"
  },
  {
      "text": "Telekommunikation, Satellit",
      "value": "Telekommunikation, Satellit"
  },
  {
      "text": "Telekommunikation, Trådbunden",
      "value": "Telekommunikation, Trådbunden"
  },
  {
      "text": "Telekommunikation, Trådlös",
      "value": "Telekommunikation, Trådlös"
  },
  {
      "text": "Telekommunikation, Övrig",
      "value": "Telekommunikation, Övrig"
  },
  {
      "text": "Telekommunikationsutrustning, Butikshandel",
      "value": "Telekommunikationsutrustning, Butikshandel"
  },
  {
      "text": "Teleprodukter, Partihandel",
      "value": "Teleprodukter, Partihandel"
  },
  {
      "text": "Textil-, Sy- & Stickmaskiner, Partihandel",
      "value": "Textil-, Sy- & Stickmaskiner, Partihandel"
  },
  {
      "text": "Textilier, Butikshandel",
      "value": "Textilier, Butikshandel"
  },
  {
      "text": "Textilier, Kläder & Skodon, Partihandel",
      "value": "Textilier, Kläder & Skodon, Partihandel"
  },
  {
      "text": "Textilier, Partihandel",
      "value": "Textilier, Partihandel"
  },
  {
      "text": "Tidningar, Butikshandel",
      "value": "Tidningar, Butikshandel"
  },
  {
      "text": "Tidningsdistribution",
      "value": "Tidningsdistribution"
  },
  {
      "text": "Tillverkning, Övrig",
      "value": "Tillverkning, Övrig"
  },
  {
      "text": "Tobak, Partihandel",
      "value": "Tobak, Partihandel"
  },
  {
      "text": "Tobaksodling",
      "value": "Tobaksodling"
  },
  {
      "text": "Tobaksvaror, Butikshandel",
      "value": "Tobaksvaror, Butikshandel"
  },
  {
      "text": "Tobaksvarutillverkning",
      "value": "Tobaksvarutillverkning"
  },
  {
      "text": "Torg- & Marknadshandel",
      "value": "Torg- & Marknadshandel"
  },
  {
      "text": "Torvutvinning",
      "value": "Torvutvinning"
  },
  {
      "text": "Totalförsvaret",
      "value": "Totalförsvaret"
  },
  {
      "text": "Trafikskoleverksamhet",
      "value": "Trafikskoleverksamhet"
  },
  {
      "text": "Transport Stödtjänster, Övriga",
      "value": "Transport Stödtjänster, Övriga"
  },
  {
      "text": "Transportmedel Övriga, Reparation",
      "value": "Transportmedel Övriga, Reparation"
  },
  {
      "text": "Transportmedel Övriga, Tillverkning",
      "value": "Transportmedel Övriga, Tillverkning"
  },
  {
      "text": "Transportmedelsindustri",
      "value": "Transportmedelsindustri"
  },
  {
      "text": "Trav- & Galoppbanor",
      "value": "Trav- & Galoppbanor"
  },
  {
      "text": "Tryckning Av Böcker & Övrigt",
      "value": "Tryckning Av Böcker & Övrigt"
  },
  {
      "text": "Tryckning Av Dagstidningar",
      "value": "Tryckning Av Dagstidningar"
  },
  {
      "text": "Tryckning Av Tidsskrifter",
      "value": "Tryckning Av Tidsskrifter"
  },
  {
      "text": "Trädgårdar, Djurparker & Naturreservat, Drift",
      "value": "Trädgårdar, Djurparker & Naturreservat, Drift"
  },
  {
      "text": "Trähus, Tillverkning",
      "value": "Trähus, Tillverkning"
  },
  {
      "text": "Trävaror, Tillverkning",
      "value": "Trävaror, Tillverkning"
  },
  {
      "text": "Turist- & Bokningsservice",
      "value": "Turist- & Bokningsservice"
  },
  {
      "text": "Tv-Program Planering",
      "value": "Tv-Program Planering"
  },
  {
      "text": "Tvätteriverksamhet",
      "value": "Tvätteriverksamhet"
  },
  {
      "text": "Tvål, Såpa & Tvättmedel, Tillverkning",
      "value": "Tvål, Såpa & Tvättmedel, Tillverkning"
  },
  {
      "text": "Tävling Med Hästar",
      "value": "Tävling Med Hästar"
  },
  {
      "text": "Universitets- & Högskoleutbildning",
      "value": "Universitets- & Högskoleutbildning"
  },
  {
      "text": "Universitets- & Högskoleutbildning Samt Forskning",
      "value": "Universitets- & Högskoleutbildning Samt Forskning"
  },
  {
      "text": "Ur & Guldsmedsvaror, Reparation",
      "value": "Ur & Guldsmedsvaror, Reparation"
  },
  {
      "text": "Ur & Guldssmedsvaror, Partihandel",
      "value": "Ur & Guldssmedsvaror, Partihandel"
  },
  {
      "text": "Uran- & Toriummalmutvinning",
      "value": "Uran- & Toriummalmutvinning"
  },
  {
      "text": "Urtillverkning",
      "value": "Urtillverkning"
  },
  {
      "text": "Utbildning, Övrig",
      "value": "Utbildning, Övrig"
  },
  {
      "text": "Utbildningsväsendet, Stödverksamhet",
      "value": "Utbildningsväsendet, Stödverksamhet"
  },
  {
      "text": "Utformning av Byggprojekt",
      "value": "Utformning av Byggprojekt"
  },
  {
      "text": "Utgivning Av Annan Programvara",
      "value": "Utgivning Av Annan Programvara"
  },
  {
      "text": "Utgivning Av Dataspel",
      "value": "Utgivning Av Dataspel"
  },
  {
      "text": "Utgivning Av Tidskrifter",
      "value": "Utgivning Av Tidskrifter"
  },
  {
      "text": "Uthyrning & Förvaltning Av Fastigheter",
      "value": "Uthyrning & Förvaltning Av Fastigheter"
  },
  {
      "text": "Uthyrning & Leasing Av Andra Hushållsartiklar & Varor För Personligt Bruk",
      "value": "Uthyrning & Leasing Av Andra Hushållsartiklar & Varor För Personligt Bruk"
  },
  {
      "text": "Uthyrning & Leasing Av Bygg- & Anläggningsmaskiner",
      "value": "Uthyrning & Leasing Av Bygg- & Anläggningsmaskiner"
  },
  {
      "text": "Uthyrning & Leasing Av Fartyg & Båtar",
      "value": "Uthyrning & Leasing Av Fartyg & Båtar"
  },
  {
      "text": "Uthyrning & Leasing Av Flygplan",
      "value": "Uthyrning & Leasing Av Flygplan"
  },
  {
      "text": "Uthyrning & Leasing Av Fritids- & Sportutrustning",
      "value": "Uthyrning & Leasing Av Fritids- & Sportutrustning"
  },
  {
      "text": "Uthyrning & Leasing Av Jordbruksmaskiner & Jordbruksredskap",
      "value": "Uthyrning & Leasing Av Jordbruksmaskiner & Jordbruksredskap"
  },
  {
      "text": "Uthyrning & Leasing Av Kontorsmaskiner & Kontorsutrustning",
      "value": "Uthyrning & Leasing Av Kontorsmaskiner & Kontorsutrustning"
  },
  {
      "text": "Uthyrning & Leasing Av Lastbilar & Andra Tunga Motorfordon",
      "value": "Uthyrning & Leasing Av Lastbilar & Andra Tunga Motorfordon"
  },
  {
      "text": "Uthyrning & Leasing Av Personbilar & Lätta Motorfordon",
      "value": "Uthyrning & Leasing Av Personbilar & Lätta Motorfordon"
  },
  {
      "text": "Uthyrning & Leasing Övrigt",
      "value": "Uthyrning & Leasing Övrigt"
  },
  {
      "text": "Uthyrning Av Videokassetter & Dvd-Skivor",
      "value": "Uthyrning Av Videokassetter & Dvd-Skivor"
  },
  {
      "text": "Uthyrning av Bygg- & Anläggningsmaskiner med förare",
      "value": "Uthyrning av Bygg- & Anläggningsmaskiner med förare"
  },
  {
      "text": "Utrikesförvaltning",
      "value": "Utrikesförvaltning"
  },
  {
      "text": "Utrustning Reparation, Övrig",
      "value": "Utrustning Reparation, Övrig"
  },
  {
      "text": "Uttjänta Fordon, Partihandel",
      "value": "Uttjänta Fordon, Partihandel"
  },
  {
      "text": "Utvinning, Stödtjänster",
      "value": "Utvinning, Stödtjänster"
  },
  {
      "text": "Vandrarhemsverksamhet",
      "value": "Vandrarhemsverksamhet"
  },
  {
      "text": "Vapen & Ammunition, Tillverkning",
      "value": "Vapen & Ammunition, Tillverkning"
  },
  {
      "text": "Varuhus- & Stormarknadshandel",
      "value": "Varuhus- & Stormarknadshandel"
  },
  {
      "text": "Vatten & Avlopp",
      "value": "Vatten & Avlopp"
  },
  {
      "text": "Verksamhet Utförd Av Försäkringsombud & Försäkringsmäklare",
      "value": "Verksamhet Utförd Av Försäkringsombud & Försäkringsmäklare"
  },
  {
      "text": "Verksamhet Utförd Av Värdepappers- & Varumäklare",
      "value": "Verksamhet Utförd Av Värdepappers- & Varumäklare"
  },
  {
      "text": "Verksamheter Som Utövas Av Huvudkontor",
      "value": "Verksamheter Som Utövas Av Huvudkontor"
  },
  {
      "text": "Verktyg & Redskap, Tillverkning",
      "value": "Verktyg & Redskap, Tillverkning"
  },
  {
      "text": "Verktygsmaskiner, Partihandel",
      "value": "Verktygsmaskiner, Partihandel"
  },
  {
      "text": "Verktygsmaskiner, Tillverkning",
      "value": "Verktygsmaskiner, Tillverkning"
  },
  {
      "text": "Veterinärverksamhet",
      "value": "Veterinärverksamhet"
  },
  {
      "text": "Virke & Byggmaterial, Partihandel",
      "value": "Virke & Byggmaterial, Partihandel"
  },
  {
      "text": "Virke & Byggvaror, Butikshandel",
      "value": "Virke & Byggvaror, Butikshandel"
  },
  {
      "text": "Virkesmätning",
      "value": "Virkesmätning"
  },
  {
      "text": "Vvs-Varor, Partihandel",
      "value": "Vvs-Varor, Partihandel"
  },
  {
      "text": "Vägtransport, Godstrafik",
      "value": "Vägtransport, Godstrafik"
  },
  {
      "text": "Värme & Kyla",
      "value": "Värme & Kyla"
  },
  {
      "text": "Väskor & Reseffekter, Butikshandel",
      "value": "Väskor & Reseffekter, Butikshandel"
  },
  {
      "text": "Vävnadstillverkning",
      "value": "Vävnadstillverkning"
  },
  {
      "text": "Växter, Bearbetning",
      "value": "Växter, Bearbetning"
  },
  {
      "text": "Växtodling",
      "value": "Växtodling"
  },
  {
      "text": "Vård & Omsorg Med Boende",
      "value": "Vård & Omsorg Med Boende"
  },
  {
      "text": "Vård Av Historiska Minnesmärken & Byggnader",
      "value": "Vård Av Historiska Minnesmärken & Byggnader"
  },
  {
      "text": "Webbportaler",
      "value": "Webbportaler"
  },
  {
      "text": "Yrkesförarutbildning",
      "value": "Yrkesförarutbildning"
  },
  {
      "text": "Äggproduktion",
      "value": "Äggproduktion"
  },
  {
      "text": "Åklagarverksamhet",
      "value": "Åklagarverksamhet"
  },
  {
      "text": "Återförsäkring",
      "value": "Återförsäkring"
  },
  {
      "text": "Öppen Hälso- & Sjukvård",
      "value": "Öppen Hälso- & Sjukvård"
  },
  {
      "text": "Översättning & Tolkning",
      "value": "Översättning & Tolkning"
  }
]