import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import * as Api from "../../Api";

export interface ICompanysProps {
  area?: string;
  city?: string;
  companyName: string;
  id: number;
  lastUpdated: string;
  industry: string;
  operation: string;
  operationNr: string;
  web?: string;
  salesman?: string;
  status: string;
}

export const useEditCompanyModal = (refreshUseCompanysList: () => void) => {
  const [selectedCompany, setSelectedCompany] = useState<ICompanysProps>();
  const [editCompanyModalState, setEditCompanyModalState] = useState(false);

  const onCloseEditCompanyModal = () => {
    setEditCompanyModalState(false);
  };

  const onOpenEditCompanyModal = useCallback(
    (company: ICompanysProps) => {
      setEditCompanyModalState(true);
      setSelectedCompany(company);
    },
    [setSelectedCompany]
  );

  const edit = useCallback(async (data: ICompanysProps) => {
    return await Api.Company.EditCompany(data);
  }, []);

  const { isLoading: isEditingCompany, mutate: onHandleEditCompany } =
    useMutation(edit, {
      onError: (error: any) => {
        toast.error(`${error}`);
      },
      onSuccess: () => {
        toast.success("Sucess!");
        onCloseEditCompanyModal();
        refreshUseCompanysList();
      },
    });

  return {
    isEditingCompany,
    selectedCompany,
    editCompanyModalState,
    onCloseEditCompanyModal,
    onOpenEditCompanyModal,
    onHandleEditCompany,
  };
};
