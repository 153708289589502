import { TextField, Tooltip } from "@mui/material";
import { Controller, FieldValues } from "react-hook-form";
import { Control } from "react-hook-form";

interface IControlTextFieldProps<T extends FieldValues = any> {
  name: string;
  control: Control<T>;
  disabled: boolean;
  placeholder?: string;
  label: string;
  type?: string;
  fullWidth?: boolean;
  className?: string;
  required?: boolean;
  defaultValue?: string;
  multiline?: boolean;
  rows?: number;
  tooltipText?: string;
}

const ControlTextField = ({
  name,
  control,
  disabled = false,
  placeholder = "",
  label,
  type = "text",
  fullWidth = true,
  className = "",
  required = false,
  defaultValue,
  multiline = false,
  rows = 0,
  tooltipText = "",
}: IControlTextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Tooltip title={tooltipText}>
            <TextField
              required={required}
              className={className}
              fullWidth={fullWidth}
              variant="outlined"
              type={type}
              value={value}
              label={label}
              placeholder={placeholder}
              multiline={multiline}
              rows={rows}
              onChange={onChange}
              error={!!error}
              disabled={disabled}
              helperText={error && error.message}
              defaultValue={defaultValue}
            />
          </Tooltip>
        );
      }}
    />
  );
};
export default ControlTextField;
