export const industrys = [
  { category: "Ambassader", label: "Ambassader & Internationella Org." },
  { category: "Avlopp", label: "Avlopp, Avfall, El & Vatten" },
  { category: "Bank", label: "Bank, Finans & Försäkring" },
  { category: "Bemanning", label: "Bemanning & Arbetsförmedling" },
  {
    category: "Bransch",
    label: "Bransch-, Arbetsgivar- & Yrkesorg.",
  },
  {
    category: "Bygg",
    label: "Bygg-, Design- & Inredningsverksamhet",
  },
  { category: "Data", label: "Data, It & Telekommunikation" },
  { category: "Detaljhandel", label: "Detaljhandel" },
  { category: "Fastighetsverksamhet", label: "Fastighetsverksamhet" },
  { category: "Foretagstjanster", label: "Företagstjänster" },
  { category: "Hotell", label: "Hotell & Restaurang" },
  { category: "Har", label: "Hår & Skönhetsvård" },
  { category: "Halsa", label: "Hälsa & Sjukvård" },
  { category: "Jordbruk", label: "Jordbruk, Skogsbruk, Jakt & Fiske" },
  {
    category: "Juridik",
    label: "Juridik, Ekonomi & Konsulttjänster",
  },
  { category: "Kultur", label: "Kultur, Nöje & Fritid" },
  { category: "Livsmedelsframstallning", label: "Livsmedelsframställning" },
  { category: "Media", label: "Media" },
  { category: "Motorfordonshandel", label: "Motorfordonshandel" },
  { category: "Offentlig", label: "Offentlig Förvaltning & Samhälle" },
  { category: "Partihandel", label: "Partihandel" },
  { category: "Reklam", label: "Reklam, Pr & Marknadsundersökning" },
  { category: "Reparation", label: "Reparation & Installation" },
  { category: "Resebyra", label: "Resebyrå & Turism" },
  { category: "Teknisk", label: "Teknisk Konsultverksamhet" },
  { category: "Tillverkning", label: "Tillverkning & Industri" },
  { category: "Transport", label: "Transport & Magasinering" },
  {
    category: "Utbildning",
    label: "Utbildning, Forskning & Utveckling",
  },
  { category: "Uthyrning", label: "Uthyrning & Leasing" },
  { category: "Ovriga", label: "Övriga Konsumenttjänster" },
];
