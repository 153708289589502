import "./App.css";
import { Companys } from "./pages/Index";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";

function App() {
  const queryClient = new QueryClient();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <Toaster
          toastOptions={{
            error: {
              duration: 5000,
            },
            success: {
              duration: 4000,
            },
          }}
        />
        <Companys />
      </QueryClientProvider>
    </div>
  );
}

export default App;

{
  /* <Route path="/login" exact component={Login} />
<Route path="/admin/kommun/:id" exact={false} component={TempKommun} /> */
}
