import Axios from "axios";
import toast from "react-hot-toast";
import { post } from "../../Api/restClient";
import { getData } from "../../pages/Index";

interface IApproveNewData {
  crawledData: getData[] | null;
  newIndustry: string;
  approvedData: () => void;
}

export const approveNewData = async ({
  crawledData,
  newIndustry,
  approvedData,
}: IApproveNewData) => {
  if (crawledData) {
    const chunkSize = 500;
    for (let i = 0; i < crawledData.length; i += chunkSize) {
      const chunk = crawledData.slice(i, i + chunkSize);
      await post("company/create", {
        companies: chunk,
        newIndustry: newIndustry,
      });
      setTimeout(() => {}, 10000);
    }
    toast.success("Sucess!");
    approvedData();
  } else {
    toast.error("Fel uppstod");
  }
};
