import { ICompanysProps } from "../../Hooks/Companys/useEditCompanyModal";
import { get, put } from "../restClient";

export const EditCompany = (data: ICompanysProps) => {
  put(`company/update/${data.id}`, { data });
};

export const getCompanys = async () => {
  return await get<ICompanysProps[]>(`companys`);
};
