import { useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";
import * as Api from "../../Api";

export interface IUsersProps {
  id: string;
  name: string;
  email: string;
  mobile: string;
  role: string;
}

export const useUsers = () => {
  const queryClient = useQueryClient();
  const { isLoading: isLoadingUsers, data: users } = useQuery<IUsersProps[]>(
    "users",
    async () => {
      return (await Api.User.getUsers()).data;
    }
  );

  const refreshUseUsers = useCallback(() => {
    queryClient.invalidateQueries("users");
  }, [queryClient]);

  return {
    isLoadingUsers,
    users,
    refreshUseUsers,
  };
};
