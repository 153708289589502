export const statusOption = [
  {
    text: "Ej kontaktad",
    value: "Ej kontaktad",
  },
  {
    text: "Kontaktad",
    value: "Kontaktad",
  },
  {
    text: "Haft möte",
    value: "Haft möte",
  },
  {
    text: "Kund",
    value: "Kund",
  },
  {
    text: "Förlorad kund",
    value: "Förlorad kund",
  },
];
