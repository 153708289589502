import { ICompanysProps } from "../../Hooks/Companys/useEditCompanyModal";
import { getData } from "../../pages/Index";
import { get, post } from "../restClient";

export const crawlCompanyData = (operation: string) => {
  const params = {
    operation: operation,
  };
  return get<getData[] | null>("scrape/new", {
    params,
  });
};

export const crawlNewInfo = (filteredCompanies: ICompanysProps[]) => {
  const data = JSON.stringify({
    filteredCompanies: filteredCompanies.slice(0, 50),
  });
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  return post("scrape/info", data, config);
};
