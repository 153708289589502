import { useEffect, useState } from "react";
import Axios from "axios";
import styled from "styled-components";
import { industrys } from "../utils/industrys";
import { Operations, SelectOperationOptions } from "../utils/operations";
import "antd/dist/antd.css";
import { Space, Table } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import EditCompanyForm from "../Components/Forms/EditCompanyForm";
import DialogWrapper from "../Components/DialogWrapper";
import {
  ICompanysProps,
  useEditCompanyModal,
} from "../Hooks/Companys/useEditCompanyModal";
import { statusOption } from "../utils/statusOptions";
import { useCompanysList } from "../Hooks/Companys/useCompanysList";
import { approveNewData } from "../Hooks/Crawl/approveNewData";
import { Button, DialogActions } from "@mui/material";
import { useCrawlNewData } from "../Hooks/Crawl/useCrawlNewData";
import { useUsers } from "../Hooks/Users";

export interface getData {
  area?: string;
  companyName: string;
  industry: string;
  operation: string;
  operationNr: string;
}

interface SelectOperation {
  url: string;
  label: string;
}

export const Companys = () => {
  const [operationNr, setOperationNr] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [operation, setOperation] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");

  const [newIndustry, setNewIndustry] = useState("");
  const [newOperation, setNewOperation] = useState("");

  const [newInfo, setNewInfo] = useState<ICompanysProps[]>();

  const { isLoadingCompanys, companys, refreshUseCompanysList } =
    useCompanysList();

  const { isLoadingUsers, users, refreshUseUsers } = useUsers();

  const {
    crawledData,
    clearCrawledData,
    handleCrawlCompany,
    handleCrawlNewInfo,
  } = useCrawlNewData(refreshUseCompanysList);

  const {
    isEditingCompany,
    selectedCompany,
    editCompanyModalState,
    onCloseEditCompanyModal,
    onOpenEditCompanyModal,
    onHandleEditCompany,
  } = useEditCompanyModal(refreshUseCompanysList);

  const approvedData = () => {
    clearCrawledData();
    refreshUseCompanysList();
  };

  const industryOptions = industrys.map((option) => ({
    text: option.category,
    value: option.category,
  }));

  const userOptions = users?.map((user) => ({
    text: user.name,
    value: user.name,
  }));

  const salesmansOptions = users?.map((user) => ({
    id: user.name,
    name: user.name,
  }));

  // const operationOptions = OperationOptions.sort().map((option) => ({
  //   text: option,
  //   value: option,
  // }));

  const checkAreas = () => {
    let areaOptions: { text: string; value: string }[] = [];
    let existingArea: string[] = [];
    companys?.map((option) => {
      if (option.area && !existingArea.includes(option.area)) {
        existingArea.push(option.area);
        areaOptions.push({
          text: option.area,
          value: option.area,
        });
      }
    });
    return areaOptions.sort((a, b) => a.value.localeCompare(b.value));
  };

  const companyColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "1",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "2",
    },
    {
      title: "Operation Nr",
      dataIndex: "operationNr",
      key: "3",
      filters: [
        {
          text: "Visa bara fullständiga nummer",
          value: "",
        },
        {
          text: "Visa bara icke fullständiga nummer",
          value: "Includes X",
        },
      ],
      onFilter: (value: any, data: any) => {
        if (value) return data.operationNr.includes("X");
        if (!value) return !data.operationNr.includes("X");
      },
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "4",
      filters: industryOptions,
      onFilter: (value: any, data: any) => data.industry.includes(value),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "5",
      filters: SelectOperationOptions,
      onFilter: (value: any, data: any) => {
        if (data.operation.toLowerCase() === value.toLowerCase()) return true;
      },
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "6",
      filters: checkAreas(),
      onFilter: (value: any, data: any) => {
        if (data.area.toLowerCase() === value.toLowerCase()) return true;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "7",
    },
    {
      title: "Web",
      dataIndex: "web",
      key: "8",
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "9",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "10",
      filters: statusOption,
      onFilter: (value: any, data: any) => data.status.indexOf(value) === 0,
    },
    {
      title: "Säljare",
      dataIndex: "salesman",
      key: "11",
      filters: userOptions,
      onFilter: (value: any, data: any) => data.salesman.indexOf(value) === 0,
    },

    {
      title: "Action",
      key: "action",
      render: (_: any, company: ICompanysProps) => (
        <Space size="middle">
          <SettingOutlined onClick={() => onOpenEditCompanyModal(company)} />
        </Space>
      ),
    },
  ];

  const submitReview = () => {
    Axios.post("http://localhost:3001/company/create", {
      operationNr: operationNr,
      companyName: companyName,
      industry: industry,
      operation: operation,
      city: city,
      area: area,
    }).then(() => {
      alert("Succes");
    });
  };

  const createUser = () => {
    Axios.post("http://localhost:3001/users/create", {
      name: "Fidel Coard",
      email: "fidel@digxco.se",
      mobile: "072 - 372 94 34",
      role: "admin",
    }).then(() => {
      alert("Succes");
    });
  };

  const searchWebData = (filteredCompanies: ICompanysProps[]) => {
    setNewInfo(filteredCompanies);
  };

  useEffect(() => {
    refreshUseCompanysList();
  }, []);

  return (
    <div className="App">
      <button onClick={() => createUser()}>Lägg till användare</button>
      {newInfo && (
        <button onClick={() => handleCrawlNewInfo(newInfo)}>
          Hämta mer information
        </button>
      )}
      <h1>Lägg till ett bolag</h1>
      <div className="form">
        <label htmlFor="companyName">Bolagsnamn</label>
        <input
          type="text"
          name="companyName"
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
        />
        <label htmlFor="operationNr">Org.nr</label>
        <input
          type="text"
          name="operationNr"
          onChange={(e) => {
            setOperationNr(e.target.value);
          }}
        />
        <label htmlFor="industry">Bransch</label>
        <input
          type="text"
          name="industry"
          onChange={(e) => {
            setIndustry(e.target.value);
          }}
        />
        <label htmlFor="operation">Verksamhet</label>
        <input
          type="text"
          name="operation"
          onChange={(e) => {
            setOperation(e.target.value);
          }}
        />
        <label htmlFor="city">Stad</label>
        <input
          type="text"
          name="city"
          onChange={(e) => {
            setCity(e.target.value);
          }}
        />
        <label htmlFor="area">Område</label>
        <input
          type="text"
          name="area"
          onChange={(e) => {
            setArea(e.target.value);
          }}
        />
        <Button onClick={() => submitReview()}>Submit</Button>
      </div>
      <label htmlFor="industry">Industry</label>
      <select
        name="industry"
        value={newIndustry}
        onChange={(e) => {
          setNewIndustry(e.target.value);
        }}
      >
        <option value="">Välj industri</option>
        {industrys.map((option, key) => (
          <option key={key} value={option.category}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor="operation">Operation</label>
      <select
        name="operation"
        value={newOperation}
        onChange={(e) => {
          setNewOperation(e.target.value);
        }}
        disabled={newIndustry ? false : true}
      >
        <option value="">{`Välj operation inom ${newIndustry}`}</option>
        {Operations.find(
          (country) => country.name === newIndustry
        )?.operations.map((option: SelectOperation, index) => (
          <option key={index} value={option.url}>
            {option.label}
          </option>
        ))}
      </select>
      <Button
        disabled={newIndustry ? false : true}
        onClick={() => handleCrawlCompany(newOperation)}
      >
        Hämta data
      </Button>
      <CompanyTableWrapper>
        <Table
          bordered
          dataSource={companys?.sort((a, b) =>
            a.companyName.localeCompare(b.companyName)
          )}
          columns={companyColumns}
          onChange={(pagination, filters, sorter, extra) => {
            searchWebData(extra.currentDataSource);
          }}
        ></Table>
      </CompanyTableWrapper>

      {crawledData && (
        <div>
          <Button
            onClick={() =>
              approveNewData({ crawledData, newIndustry, approvedData })
            }
          >
            Godkän data
          </Button>
          <Button>Hämta mer data</Button>
        </div>
      )}

      {selectedCompany && (
        <DialogWrapper
          title="Edit company info"
          open={editCompanyModalState}
          onClose={onCloseEditCompanyModal}
          maxWidth="md"
        >
          <EditCompanyForm
            currentCompany={selectedCompany}
            salesmans={
              salesmansOptions
                ? [
                    ...salesmansOptions,
                    {
                      id: "No salesman",
                      name: "No salesman",
                    },
                  ]
                : [
                    {
                      id: "No salesman",
                      name: "No salesman",
                    },
                  ]
            }
            disabled={isEditingCompany}
            // onEdited={onEdited}
            updateComopany={onHandleEditCompany}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

const TableRow = styled.tr`
  height: 50px;
  &:nth-child(2n + 1) {
    background-color: darkgrey;
  }
`;

const TableData = styled.td`
  text-align: left;
  padding: 10px;
`;

const CompanyTableWrapper = styled.div`
  width: 90%;
  margin-left: 5%;
`;
