import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
// import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
// import { initializeTenant } from "utils/tenants"
// import { destroyToken, getCurrentTokens, setTokenAndRefreshToken } from "utils/tokenHelpers"

// initializeTenant()

// const axiosRequestConfig: AxiosRequestConfig = {
//   baseURL: baseUrl,
//   headers: {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//   },
// }

// const axiosInstance: AxiosInstance = axios.create(axiosRequestConfig)

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token")
//     if (!config.headers) {
//       config.headers = {}
//     }
//     config.headers.Authorization = "Bearer " + token
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

// let refreshRequest: Promise<any> | null = null

// axiosInstance.interceptors.response.use(
//   //valid response return immidately
//   (response) => response,
//   async (error) => {
//     if (!error.response) {
//       // If server is down or no internet connection return error immediately
//       // example net::ERR_CONNECTION_REFUSED
//       return Promise.reject(error)
//     }
//     if (error.response.status === 401) {
//       if (!refreshRequest) {
//         // works because it returns a promise and a promise is cacheable
//         refreshRequest = sendRefreshRequest(getCurrentTokens())
//       }
//       const { data } = await refreshRequest
//       setTokenAndRefreshToken(data.token, data.refreshToken)
//       axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + data.token
//       refreshRequest = null
//       if (data) {
//         return axiosInstance(error.config)
//       }
//     }

//     return Promise.reject(error)
//   }
// )

// interface ITokenRequest {
//   token: string | null
//   refreshToken: string | null
// }

// const sendRefreshRequest = async ({ token, refreshToken }: ITokenRequest) => {
//   try {
//     return await axios.post(`${window.tenant.api}/Token/refresh`, { token, refreshToken })
//   } catch (error) {
//     return Promise.reject(error)
//   }
// }

// function handleUnauthorized() {
//   destroyToken()
//   window.location.href = "/login"
// }

// function handleError(error: any) {
//   if (error.response?.status === 401) {
//     handleUnauthorized()
//   }
//   return error
// }

// Add this to all requests in the catch
// throw handleError(error)

// Add this instead of get or post etc
// return await axiosInstance.get(resourceEndpointPath, config)

const baseUrl = window.origin.includes("localhost")
  ? "http://localhost:3001/"
  : "https://techzum-test-cms.vercel.app/";

export async function get<T>(
  resourceEndpointPath: string,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
  try {
    return await Axios.get(baseUrl + resourceEndpointPath, config);
  } catch (error) {
    throw error;
  }
}

export async function post<T>(
  resourceEndpointPath: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
  try {
    return await Axios.post(baseUrl + resourceEndpointPath, data, config);
  } catch (error) {
    throw error;
  }
}

export async function put<T>(
  resourceEndpointPath: string,
  data?: any
): Promise<AxiosResponse<T>> {
  try {
    return await Axios.put(baseUrl + resourceEndpointPath, data);
  } catch (error) {
    throw error;
  }
}

export async function destroy<T>(
  resourceEndpointPath: string
): Promise<AxiosResponse<T>> {
  try {
    return await Axios.delete(baseUrl + resourceEndpointPath);
  } catch (error) {
    throw error;
  }
}
