import { useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";
import * as Api from "../../Api";
import { ICompanysProps } from "./useEditCompanyModal";

export const useCompanysList = () => {
  const queryClient = useQueryClient();

  const { isLoading: isLoadingCompanys, data: companys } = useQuery<
    ICompanysProps[]
  >("companys", async () => {
    return (await Api.Company.getCompanys()).data;
  });

  const refreshUseCompanysList = useCallback(() => {
    queryClient.invalidateQueries("companys");
  }, [queryClient]);

  return {
    isLoadingCompanys,
    companys,
    refreshUseCompanysList,
  };
};
